import {
  Autocomplete,
  MenuItem,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import React from 'react';
interface ILocationItemMini {
  name?: string;
  id?: string;
}
interface LocationAutocompleteProps {
  locationList: { name: string; id: string }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectedLocationChange: (event: any, newValue: any) => void;
  sx?: SxProps<Theme>;
  defaultValue?: ILocationItemMini;
  value?: ILocationItemMini;
  isOptionEqualToValue?: (
    option: ILocationItemMini,
    value: ILocationItemMini
  ) => boolean;
}

const LocationAutocomplete: React.FC<LocationAutocompleteProps> = ({
  locationList,
  onSelectedLocationChange,
  sx,
  defaultValue,
  isOptionEqualToValue,
  value,
}) => {
  return (
    <Autocomplete
      disablePortal
      id="location-autocomplete"
      options={locationList}
      sx={{ ...{ width: 300 }, ...sx }}
      getOptionLabel={(option) => option?.name || ''}
      onChange={onSelectedLocationChange}
      defaultValue={defaultValue}
      isOptionEqualToValue={isOptionEqualToValue}
      value={value}
      renderOption={(props, option) => {
        return (
          <MenuItem {...props} key={option?.id} value={option?.id}>
            <div>{option?.name}</div>
          </MenuItem>
        );
      }}
      renderInput={(params) => <TextField {...params} label="Location" />}
    />
  );
};

export default LocationAutocomplete;
