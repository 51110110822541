import { cibusCellDomainValidator, emailExpression } from 'constants/common';
import { ProjectDetails } from 'types/project';

export const CreateProjectValidator = (
  projectData: ProjectDetails,
  userAvatar: Blob | undefined,
  projectLogo: Blob | undefined
) => {
  let publishError = {};
  if (projectData.name?.trim() === '' || projectData.name === null) {
    publishError = {
      ...publishError,
      name: 'Project Name cannot be empty!',
    };
  }
  if (projectData.address?.trim() === '' || projectData.address === null) {
    publishError = {
      ...publishError,
      address: 'Address cannot be empty!',
    };
  }
  if (
    projectData.description?.trim() === '' ||
    projectData.description === null
  ) {
    publishError = {
      ...publishError,
      description: 'Description cannot be empty!',
    };
  }
  if (projectData.firstName?.trim() === '' || projectData.firstName === null) {
    publishError = {
      ...publishError,
      firstName: 'First Name cannot be empty!',
    };
  }
  if (projectData.lastName?.trim() === '' || projectData.lastName === null) {
    publishError = {
      ...publishError,
      lastName: 'Last Name cannot be empty!',
    };
  }
  if (projectData.email?.trim() === '' || projectData.email === null) {
    publishError = {
      ...publishError,
      email: 'User Principal Name cannot be empty!',
    };
  } else if (projectData.email) {
    const validEmail = cibusCellDomainValidator.test(projectData.email);
    if (!validEmail) {
      publishError = {
        ...publishError,
        email: 'Principal Name should end with "@cibuscell.com"!',
      };
    }
  }
  if (
    projectData.secondaryEmail?.trim() === '' ||
    projectData.secondaryEmail === null
  ) {
    publishError = {
      ...publishError,
      secondaryEmail: 'Secondary Email cannot be empty!',
    };
  } else if (projectData.secondaryEmail) {
    const validEmail = emailExpression.test(projectData.secondaryEmail);
    if (!validEmail) {
      publishError = {
        ...publishError,
        secondaryEmail: 'Please enter a valid Email!',
      };
    }
  }
  if (userAvatar === undefined) {
    publishError = {
      ...publishError,
      userAvatar: 'User Avatar cannot be empty!',
    };
  }
  if (projectLogo === undefined) {
    publishError = {
      ...publishError,
      projectLogo: 'Project Logo cannot be empty!',
    };
  }
  return publishError;
};
