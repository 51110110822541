export const newProjectCreationMsgTeams = (data: {
  projectDetails: { name: string };
  projectId: string;
  user: { username: string; password: string };
}) => {
  const message =
    'A new Project ' +
    data.projectDetails.name +
    ' is created with id : ' +
    data.projectId +
    '. ' +
    ' Also, the initial user is created with username ' +
    data.user.username +
    ' and password ' +
    data.user.password;
  return message;
};

export const addNewUserToProjectMsgTeams = (data: {
  projectName: string;
  projectId: string;
  user: { username: string; password: string };
}) => {
  const message =
    'A new User is added to the project ' +
    data.projectName +
    ' with PROJECT id : ' +
    data.projectId +
    '. ' +
    'The username is ' +
    data.user.username +
    ' and password ' +
    data.user.password;
  return message;
};
