import './ADX-Dashboard.scss';
import { Alert, Button, Snackbar } from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import SProjectService from '../../services/project/project.service';
import FormControl from '@mui/material/FormControl';
import ProjectAutocomplete from '../../components/ProjectAutocomplete/ProjectAutocomplete';

const ADXDashboard: React.FunctionComponent = () => {
  const [projectDetails, setProjectDetails] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [adxDashboardLink, setAdxDashboardLink] = useState('');
  const [adxCluster, setAdxCluster] = useState('');
  const [adxTable, setAdxTable] = useState('');
  const [adxDatabase, setAdxDatabase] = useState('');
  const [iotHub, setIotHub] = useState('');
  const [C2Ddevice, setC2Ddevice] = useState('');
  const [ownerKey, setOwnerKey] = useState('');
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [disable, setDisabled] = useState(true);

  useEffect(() => {
    SProjectService.findNames()
      .then((response) => {
        setProjectDetails(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const transformNullInputfield = (val: any) => {
    if (val == null) {
      val = '';
    }
    return val;
  };
  const transformEmptyInputField = (val: any) => {
    if (val == '') {
      val = null;
    }
    return val;
  };

  const handleChangeProject = (
    e: SyntheticEvent<Element, Event>,
    newValue: { name: string; id: string }
  ) => {
    setSelectedProjectId(newValue?.id);
    if (newValue?.id) {
      SProjectService.getProjectDetails(newValue?.id).then(
        (projectResponse) => {
          setAdxCluster(transformNullInputfield(projectResponse.ADXCluster));
          setAdxDatabase(transformNullInputfield(projectResponse.ADXDatabase));
          setAdxTable(transformNullInputfield(projectResponse.ADXTable));
          setAdxDashboardLink(
            transformNullInputfield(projectResponse.ADXDashboardLink)
          );
          setC2Ddevice(transformNullInputfield(projectResponse.IoTDeviceId));
          setIotHub(transformNullInputfield(projectResponse.IoTHub));
          setOwnerKey(transformNullInputfield(projectResponse.iothubownerKey));
        }
      );
    }
    setDisabled(false);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setSelectedProjectId('');
    setAdxCluster('');
    setAdxDashboardLink('');
    setC2Ddevice('');
    setIotHub('');
    setOwnerKey('');
    setAdxDatabase('');
    setAdxTable('');
    setOpen(false);
    if (reason === 'clickaway') {
      return;
    }
  };
  const saveADXDetails = () => {
    const data = {
      ADXDashboardLink: transformEmptyInputField(adxDashboardLink),
      ADXCluster: transformEmptyInputField(adxCluster),
      ADXTable: transformEmptyInputField(adxTable),
      ADXDatabase: transformEmptyInputField(adxDatabase),
      IoTHub: transformEmptyInputField(iotHub),
      IoTDeviceId: transformEmptyInputField(C2Ddevice),
      iothubownerKey: transformEmptyInputField(ownerKey),
    };
    SProjectService.updateProjectDetails(data, selectedProjectId).then(() => {
      setDisabled(true);
      setSaveSuccess(true);
      setOpen(true);
    });
  };

  const cancelADXDetails = () => {
    setSelectedProjectId('');
    setAdxCluster('');
    setAdxDatabase('');
    setAdxTable('');
    setAdxDashboardLink('');
    setC2Ddevice('');
    setIotHub('');
    setOwnerKey('');
    setDisabled(true);
  };

  const buttonColor = disable ? '#D3D3D3' : '#234C8A';

  return (
    <div className="ADX-main-container">
      <div className="adx-details-title"> ADX Dashboard</div>
      <div className="setting-details-title-project">
        {
          <FormControl required sx={{ mb: 5, minWidth: 360 }}>
            <ProjectAutocomplete
              projectsList={projectDetails}
              sx={{ width: 360 }}
              onSelectedProjectChange={handleChangeProject}
            />
          </FormControl>
        }
      </div>

      <div className="setting-details-title-projectId">
        Selected Project Id: {selectedProjectId}
      </div>

      <div className="ADX-Dashboard">
        <div className="contentTextElect" style={{ marginLeft: '-2px' }}>
          <TextField
            margin="dense"
            id="ADX-Dashboard-Link"
            label="ADX Dashboard Link"
            type="string"
            value={adxDashboardLink}
            name="ADX-Dashboard-Link"
            onChange={(event) => {
              setAdxDashboardLink(event.target.value);
            }}
            sx={{ mb: 5, minWidth: 360, fontFamily: 'Inria Sans' }}
            inputProps={{
              style: { fontFamily: 'Inria Sans' },
            }}
          />
        </div>
      </div>

      <div className="ADX-Cluster">
        <div className="contentTextElect" style={{ marginLeft: '-2px' }}>
          <TextField
            margin="dense"
            id="ADX-Cluster"
            label="ADX Cluster"
            type="string"
            value={adxCluster}
            name="ADX-Cluster"
            onChange={(event) => {
              setAdxCluster(event.target.value);
            }}
            sx={{ mb: 5, minWidth: 360, fontFamily: 'Inria Sans' }}
            inputProps={{
              style: { fontFamily: 'Inria Sans' },
            }}
          />
        </div>
      </div>

      <div className="ADX-Database">
        <div className="contentTextElect" style={{ marginLeft: '-2px' }}>
          <TextField
            margin="dense"
            id="ADX-Database"
            label="ADX Database"
            type="string"
            value={adxDatabase}
            name="ADX-Database"
            onChange={(event) => {
              setAdxDatabase(event.target.value);
            }}
            sx={{ mb: 5, minWidth: 360, fontFamily: 'Inria Sans' }}
            inputProps={{
              style: { fontFamily: 'Inria Sans' },
            }}
          />
        </div>
      </div>

      <div className="ADX-Table">
        <div className="contentTextElect" style={{ marginLeft: '-2px' }}>
          <TextField
            margin="dense"
            id="ADX-Table"
            label="ADX Table"
            type="string"
            value={adxTable}
            name="ADX-Table"
            onChange={(event) => {
              setAdxTable(event.target.value);
            }}
            sx={{ mb: 5, minWidth: 360, fontFamily: 'Inria Sans' }}
            inputProps={{
              style: { fontFamily: 'Inria Sans' },
            }}
          />
        </div>
      </div>

      <div className="IOT-Hub">
        <div className="contentTextElect" style={{ marginLeft: '-2px' }}>
          <TextField
            margin="dense"
            id="IOT-Hub"
            label="IOT Hub"
            type="string"
            value={iotHub}
            name="IOT-Hub"
            onChange={(event) => {
              setIotHub(event.target.value);
            }}
            sx={{ mb: 5, minWidth: 360, fontFamily: 'Inria Sans' }}
            inputProps={{
              style: { fontFamily: 'Inria Sans' },
            }}
          />
        </div>
      </div>

      <div className="C2D-Device">
        <div className="contentTextElect" style={{ marginLeft: '-2px' }}>
          <TextField
            margin="dense"
            id="C2D-Device"
            label="C2D Device"
            type="string"
            value={C2Ddevice}
            name="C2D-Device"
            onChange={(event) => {
              setC2Ddevice(event.target.value);
            }}
            sx={{ mb: 5, minWidth: 360, fontFamily: 'Inria Sans' }}
            inputProps={{
              style: { fontFamily: 'Inria Sans' },
            }}
          />
        </div>
      </div>

      <div className="Owner-Key">
        <div className="contentTextElect" style={{ marginLeft: '-2px' }}>
          <TextField
            margin="dense"
            id="Owner-Key"
            label="Owner Key"
            type="string"
            value={ownerKey}
            name="Owner-Key"
            onChange={(event) => {
              setOwnerKey(event.target.value);
            }}
            sx={{ mb: 5, minWidth: 360, fontFamily: 'Inria Sans' }}
            inputProps={{
              style: { fontFamily: 'Inria Sans' },
            }}
          />
        </div>
      </div>
      <div className="save-button">
        <Button
          className="save-button"
          sx={{
            backgroundColor: buttonColor,
            color: '#ffff',
            '&:hover': {
              backgroundColor: '#ffff',
              color: '#234c8a',
            },
          }}
          variant="outlined"
          onClick={saveADXDetails}
          disabled={disable}
        >
          Save
        </Button>
        <div className="button-spacing">
          <Button
            className="cancel-button"
            sx={{
              backgroundColor: buttonColor,
              color: '#ffff',
              '&:hover': {
                backgroundColor: '#ffff',
                color: '#234c8a',
              },
            }}
            variant="outlined"
            onClick={cancelADXDetails}
            disabled={disable}
          >
            Cancel
          </Button>
        </div>
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={saveSuccess ? 'success' : 'error'}
            sx={{ width: '100%' }}
          >
            {saveSuccess ? 'Save Successful' : 'Save  Failed'}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default ADXDashboard;
