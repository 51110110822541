import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { matches } from 'lodash';

import {
  IReportDetails,
  PowerBIReportTypes,
  ReportStatus,
} from 'types/reports';
import { defaultReportDetails, defaultReportError } from 'constants/reports';

import { ErrorLabel, ReportContainer } from './style';
import SProjectService from '../../services/project/project.service';
import SReportsService from '../../services/reports/reports.service';
import { reportInputValidator } from '../../utils/reports';
import ListReports from './ListReports';
import ProjectAutocomplete from '../../components/ProjectAutocomplete/ProjectAutocomplete';

const Reports: React.FunctionComponent<any> = (props: {
  projectId?: string;
}) => {
  const { projectId } = props;
  const [projectsList, setProjectsList] = useState([]);
  const [reportsList, setReportsList] = useState([]);
  const [reportsLoading, setReportsLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState('');
  const [reportError, setReportError] = useState(defaultReportError);
  const [reportDetails, setReportDetails] = useState(defaultReportDetails);
  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [selectedReportId, setSelectedReportId] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setReportDetails(defaultReportDetails);
    setOpen(false);
    setIsEdit(false);
  };

  //   useEffect to fetch names of all projects on page load
  useEffect(() => {
    SProjectService.findNames()
      .then((response) => {
        setProjectsList(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const fetchAllReports = useCallback(() => {
    setReportsLoading(true);
    SReportsService.getReportsByProjectId(selectedProject)
      .then((response) => {
        setReportsList(response);
        setReportsLoading(false);
      })
      .catch((error) => {
        setReportsLoading(false);
        console.error(error);
      });
  }, [selectedProject]);

  useEffect(() => {
    if (projectId) {
      setSelectedProject(projectId);
    }
  }, [projectId]);

  //   useEffect to fetch  all reports on selected project change
  useEffect(() => {
    if (selectedProject) {
      fetchAllReports();
    }
  }, [fetchAllReports, selectedProject]);

  const onReportDetailsChange = (element: string, value: string) => {
    setReportDetails((prev) => {
      return { ...prev, [element]: value };
    });
    setReportError((prev) => {
      return { ...prev, [element]: '' };
    });
  };

  const onReportTypeChange = (e: any) => {
    setReportDetails((prev) => {
      return { ...prev, powerBIReportTypes: e.target.value };
    });
  };

  const onReportStatusTypeChange = (e: any) => {
    setReportDetails((prev) => {
      return { ...prev, reportStatus: e.target.value };
    });
  };

  const onSelectedProjectChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: { name: string; id: string }
  ) => {
    setReportsLoading(true);
    setSelectedProject(newValue?.id);
  };

  //   Variable storing props of input fields
  const inputFieldMaps = [
    {
      label: 'Report Id',
      value: reportDetails.reportId,
      required: true,
      element: 'reportId',
      error: reportError.reportId,
    },
    {
      label: 'Dataset Id',
      value: reportDetails.datasetId,
      required: true,
      element: 'datasetId',
      error: reportError.datasetId,
    },
    {
      label: 'Workspace Id',
      value: reportDetails.workspaceId,
      required: true,
      element: 'workspaceId',
      error: reportError.workspaceId,
    },
    {
      label: 'Report Name',
      value: reportDetails.reportName,
      required: false,
      element: 'reportName',
    },
    {
      label: 'Role Name',
      value: reportDetails.roleName,
      required: false,
      element: 'roleName',
    },
  ];

  const onReportEdit = (report: IReportDetails & { id: string }) => {
    const {
      powerBIReportTypes,
      reportStatus,
      reportId,
      datasetId,
      workspaceId,
      reportName,
      roleName,
    } = report;
    setIsEdit(true);
    setSelectedReportId(report?.id);
    setReportDetails({
      powerBIReportTypes,
      reportStatus,
      reportId,
      datasetId,
      reportName,
      workspaceId,
      roleName,
    });
    setOpen(true);
  };

  // handles API call to update an existing report
  const handleEditReport = useCallback(() => {
    SReportsService.updatePowerBIReport(
      {
        ...reportDetails,
        projectId: selectedProject,
      },
      selectedReportId
    )
      .then(() => {
        setReportDetails(defaultReportDetails);
        fetchAllReports();
        setOpen(false);
      })
      .catch((error) => {
        setOpen(false);
        alert(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportDetails, selectedProject, selectedReportId]);

  // handles API call to create a new report
  const handleCreateNewReport = useCallback(() => {
    SReportsService.createNewPowerBIReport({
      ...reportDetails,
      projectId: selectedProject,
    })
      .then(() => {
        setReportDetails(defaultReportDetails);
        fetchAllReports();
        setOpen(false);
      })
      .catch((error) => {
        setOpen(false);
        alert(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportDetails, selectedProject]);

  // handles API call to update an existing report
  const handleDeleteReport = (id: string) => {
    SReportsService.deletePowerBIReport(id)
      .then(() => {
        fetchAllReports();
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const handleSaveReport = () => {
    const errors = reportInputValidator(reportDetails);
    // If there are no validation errors, then set error obj to empty and save report in backend
    if (matches(errors)({})) {
      setReportError(defaultReportError);
      // TODO: set reportName & roleName to null if empty
      if (isEdit) {
        handleEditReport();
      } else {
        handleCreateNewReport();
      }
    } else {
      setReportError((prev) => {
        return { ...prev, ...errors };
      });
    }
  };

  const reportTypes = Object.keys(PowerBIReportTypes);
  const reportStatuses = Object.keys(ReportStatus);

  return (
    <ReportContainer>
      <>
        <>
          <Typography variant="h5" sx={{ mb: 4, color: 'green' }}>
            Power BI Reports
          </Typography>
          <FormControl required sx={{ mb: 5, minWidth: 360 }}>
            <ProjectAutocomplete
              projectsList={projectsList}
              onSelectedProjectChange={onSelectedProjectChange}
            />
          </FormControl>
        </>

        {selectedProject && (
          <ListReports
            loading={reportsLoading}
            reportsList={reportsList}
            onReportEdit={onReportEdit}
            handleDeleteReport={handleDeleteReport}
          />
        )}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle sx={{ marginLeft: '150px' }}>
            {isEdit ? 'Edit PowerBI Report' : 'Create New PowerBI Report'}
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                marginLeft: '150px',
                marginRight: '150px',
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FormControl required sx={{ mb: 5, mt: 5, minWidth: 360 }}>
                <InputLabel id="report-select-label">Report Type</InputLabel>
                <Select
                  labelId="report-type-label"
                  id="report-type-select"
                  size="medium"
                  label="Report Type"
                  value={reportDetails.powerBIReportTypes}
                  onChange={onReportTypeChange}
                >
                  {reportTypes?.map((type: string, index: number) => {
                    return (
                      <MenuItem key={index} value={type}>
                        <div>{type}</div>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {/* Only show status field for custom reports */}
              {reportDetails?.powerBIReportTypes ===
                PowerBIReportTypes.CUSTOM && (
                <FormControl required sx={{ mb: 5, minWidth: 360 }}>
                  <InputLabel id="status-select-label">Status</InputLabel>
                  <Select
                    labelId="status-type-label"
                    id="status-type-select"
                    size="medium"
                    label="Status"
                    value={reportDetails.reportStatus}
                    onChange={onReportStatusTypeChange}
                  >
                    {reportStatuses?.map((status: string, index: number) => {
                      return (
                        <MenuItem key={index} value={status}>
                          <div>{status}</div>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}

              {inputFieldMaps.map((entry, index) => {
                return (
                  <FormControl key={index} sx={{ minWidth: 360 }}>
                    <TextField
                      size="medium"
                      label={entry.label}
                      type="string"
                      value={entry.value}
                      required={entry.required}
                      onChange={(e) => {
                        onReportDetailsChange(entry.element, e.target.value);
                      }}
                      inputProps={{
                        maxLength: 200,
                      }}
                    />
                    <ErrorLabel>{entry.error || ''}</ErrorLabel>
                  </FormControl>
                );
              })}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" onClick={handleSaveReport}>
              {isEdit ? 'Update Report' : 'Save Report'}
            </Button>
          </DialogActions>
        </Dialog>
      </>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        disabled={!selectedProject || reportsLoading}
      >
        Add New Report
      </Button>
    </ReportContainer>
  );
};

export default Reports;
