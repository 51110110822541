export const msalConfig = {
  auth: {
    clientId: '54393b7a-0f2f-48c8-b275-9c23ec258ae3',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['b12c3523-6472-444b-a90b-eca1bc65df11/default'],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/',
};

export const cibuscellTeamsForNotification = {
  link: 'https://graph.microsoft.com/v1.0/teams/b7f2c3dd-7bc7-41c4-ad62-12e11311444b/channels/19%3a038765eaf35b4c32abe31c93e1720d9f%40thread.tacv2/messages',
};
