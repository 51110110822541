import { FunctionComponent, useState } from 'react';
import { Button, Grid } from '@mui/material';
import ProjectList from './Project-list';
import NewProjectDialog from './New-Project-Dialog';
import graphIcon1 from 'assets/images/graphIcon1.jpg';
import graphIcon2 from 'assets/images/graphIcon2.jpg';
import graphIcon3 from 'assets/images/graphIcon3.jpg';
import graphIcon4 from 'assets/images/graphIcon4.jpg';
import './Project.scss';

const Project: FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(true);

  const handleOpenNewProjectDialog = () => {
    setOpen(true);
  };

  return (
    <div className="project-main-container">
      <div className="project-overview-header-container">
        <div className="project-details-title"> Project Details</div>
        <div className="new-project-button-container">
          <Button
            variant="outlined"
            className="new-project-button"
            onClick={handleOpenNewProjectDialog}
          >
            New CibusCell Project
          </Button>
        </div>
      </div>
      <Grid className="project-overview-row-container" container spacing={1}>
        <Grid className="project-overview-cell-container" item xs={2.7}>
          <div className="project-overview-cell-column">
            <div className="project-overview-cell-row1">Total Users</div>
            <div className="project-overview-cell-row2">147</div>
          </div>
          <div className="project-overview-cell-column">
            <img className="project-overview-graph-icon" src={graphIcon1}></img>
          </div>
        </Grid>
        <Grid className="project-overview-cell-container" item xs={2.7}>
          <div className="project-overview-cell-column">
            <div className="project-overview-cell-row1">Active Users</div>
            <div className="project-overview-cell-row2">42</div>
          </div>
          <div className="project-overview-cell-column">
            <img className="project-overview-graph-icon" src={graphIcon2}></img>
          </div>
        </Grid>
        <Grid className="project-overview-cell-container" item xs={2.7}>
          <div className="project-overview-cell-column">
            <div className="project-overview-cell-row1">Total Projects</div>
            <div className="project-overview-cell-row2">15</div>
          </div>
          <div className="project-overview-cell-column">
            <img className="project-overview-graph-icon" src={graphIcon3}></img>
          </div>
        </Grid>
        <Grid className="project-overview-cell-container" item xs={2.7}>
          <div className="project-overview-cell-column">
            <div className="project-overview-cell-row1">Active Projects</div>
            <div className="project-overview-cell-row2">3</div>
          </div>
          <div className="project-overview-cell-column">
            <img className="project-overview-graph-icon" src={graphIcon4}></img>
          </div>
        </Grid>
      </Grid>
      <ProjectList refresh={refresh} setRefresh={setRefresh}></ProjectList>
      <NewProjectDialog
        open={open}
        setOpen={setOpen}
        setRefresh={setRefresh}
      ></NewProjectDialog>
    </div>
  );
};

export default Project;
