import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import './Teams.scss';
import { ChangeEvent, FunctionComponent, useState } from 'react';
import STeamsService from 'services/teams/teams.service';

import HelpIcon from '@mui/icons-material/Help';
import SLocationService from 'services/location/location.service';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';
import { TeamsFormData } from 'interfaces/teams.interface';
interface ITeamsSettingProps {
  formData: TeamsFormData;
  locationId: string;
  projectType?: number;
  memberAdditionError: string | null;
  userName?: string;
}
export const TeamsSettingsFragment: FunctionComponent<ITeamsSettingProps> = (
  props
) => {
  const { formData, locationId, memberAdditionError, userName } = props;
  const [isLoading, setLoading] = useState(false);
  const [isWebHookLoading, setWebHookLoading] = useState(false);
  const [taskDetails, setTaskDetails] = useState({
    description: '',
  });
  const [webHookDetails, setWebHookDetails] = useState({
    message: '',
    webhook: '',
  });

  const [isPlannerTutorialOpen, setPlannerTutorialOpen] = useState(true);
  const handleClosePlannerTutorialDialog = () => {
    setPlannerTutorialOpen(false);
  };
  const [isWebhookTutorialOpen, setWebhookTutorialOpen] = useState(false);
  const handleCloseWebhookTutorialDialog = () => {
    setWebhookTutorialOpen(false);
  };

  const { showSnackbar } = useSnackbar();

  const handleTaskFormChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setTaskDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleWebHookFormChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    setWebHookDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const createNewTask = () => {
    const groupId = formData.teamsDetails.notificationLink?.split('/');
    const index = groupId.indexOf('teams');
    setLoading(true);
    const postApi = 'https://graph.microsoft.com/v1.0/planner/tasks';
    const planAPI =
      'https://graph.microsoft.com/v1.0/groups/' +
      groupId[index + 1] +
      '/planner/plans';

    STeamsService.getDetails(planAPI)
      .then((response1) => {
        setLoading(false);
        const body = {
          planId: response1.data.value[0].id,
          title: taskDetails.description,
          priority: 4,
          assignments: {},
          appliedCategories: {},
        };
        STeamsService.createNewClaimsInTeams(postApi, body)
          .then(() => {
            setLoading(false);
            showSnackbar(
              'A new Task is created. You can find it in the task planner!',
              'success'
            );
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            showSnackbar(
              'Unfortunately, the task creation failed. Please try again!',
              'error'
            );
          });
      })
      .catch(() => {
        setLoading(false);
        showSnackbar(
          'Unfortunately, the task creation failed. Please try again!',
          'error'
        );
      });
  };
  const addWebHookToProject = () => {
    setWebHookLoading(true);

    SLocationService.updateLocationDetails(locationId, {
      connectorUrl: webHookDetails.webhook,
    })
      .then(() => {
        setWebHookLoading(false);
        showSnackbar(
          'WebHook is added successfully to the project!',
          'success'
        );
      })
      .catch(() => {
        setWebHookLoading(false);
        showSnackbar(
          'Unfortunately, the updation failed. Please try again!',
          'success'
        );
      });
  };

  return (
    <Grid
      container
      xs={12}
      className=" project-container teams-settings-container"
    >
      {/* Dialog for planner task creation tutorial */}
      <Dialog
        open={isPlannerTutorialOpen}
        onClose={handleClosePlannerTutorialDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Alert severity="info" className="alert-body" icon={false}>
            <div>
              <h2 className="center-alert">Create Task Planner in Teams</h2>
              <p>
                {
                  "You have successfully created a teams. Now let's create a task planner for the teams"
                }
              </p>

              <p>
                To create a task planner in Microsoft Teams, follow these steps:
              </p>
              <ol>
                <li>
                  Open Microsoft Teams and navigate to your desired team or
                  channel.
                </li>
                <li>{'Click on the "+" icon to the left of the chatbox.'}</li>
                <li>{'Select "Planner" from the list of available apps.'}</li>
                <li>
                  Follow the prompts to create a new task plan, add tasks, and
                  assign them to team members.
                </li>
              </ol>
              <p>
                {
                  "That's it! You've successfully created a task planner in Teams."
                }
              </p>
              <Button
                className="center-alert"
                variant="contained"
                color="success"
                onClick={handleClosePlannerTutorialDialog}
              >
                Done!
              </Button>
            </div>
          </Alert>
        </DialogContent>
      </Dialog>
      {/* Dialog for webhook creation tutorial */}
      <Dialog
        open={isWebhookTutorialOpen}
        onClose={handleCloseWebhookTutorialDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Alert severity="info" className="webhook-alert-body" icon={false}>
            <div>
              <h2>Create a Workflow in Microsoft Teams</h2>
              <p>
                You have successfullt created the Planner. Nextly you need to
                create a workflow. Follow these steps:
              </p>
              <ol>
                <li>
                  {'Open Microsoft Teams and navigate to your desired channel.'}
                </li>
                <li>
                  {'Click on the three dots('} <b>{'... '}</b>
                  {') next to the channel name to open the menu.'}
                </li>
                <li>{'Select "Workflows" from the menu.'}</li>
                <li>
                  {
                    'Select/Search for "Post to a channel when a webhook request is received" from the "Notify a team" section'
                  }
                </li>
                <li>
                  {'Now give a name to the workflow(optional) and click "Next"'}
                </li>
                <li>
                  {
                    'Then verify the Teams Name & Teams Channel and then click "Add workflow"'
                  }
                </li>
                <li>
                  <b> {'Copy the workflow URL provided!'} </b>
                </li>
              </ol>
              <p>
                {"That's it! You've successfully created a workflow in Teams."}
              </p>
              <Button
                className="center-alert"
                variant="contained"
                color="success"
                onClick={handleCloseWebhookTutorialDialog}
              >
                Done!
              </Button>
            </div>
          </Alert>
        </DialogContent>
      </Dialog>

      <>
        <Grid container xs={12}>
          <Grid xs={12}>
            <Typography variant="h6" gutterBottom>
              Your Teams Details
            </Typography>
            {memberAdditionError && (
              <Grid xs={12} className="member-addition-error-container">
                Adding User to Teams Failed. Error :{' '}
                <strong>{memberAdditionError}</strong> It can be because of the
                Microsoft 365 License. Please add the user Manually. Username :{' '}
                {userName}
              </Grid>
            )}
          </Grid>
          <Grid xs={6} container className="project-details-container">
            <TextField
              className="project-detail-input"
              disabled
              autoFocus
              margin="dense"
              id="description"
              label="Teams Name"
              type="text"
              variant="outlined"
              value={formData?.teamsDetails?.teamsName || ''}
            />
          </Grid>
          <Grid xs={6} container className="project-details-container">
            <TextField
              className="project-detail-input"
              disabled
              autoFocus
              margin="dense"
              id="description"
              label="Channel Name"
              type="text"
              variant="outlined"
              value={formData?.teamsDetails?.channelName || ''}
            />
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid xs={12}>
            <Typography variant="h6" gutterBottom>
              Task Planner
              <Tooltip
                title="Click for more info on how to create task planner"
                arrow
              >
                <Button
                  onClick={() => {
                    setPlannerTutorialOpen(true);
                  }}
                  sx={{ mt: 1, mr: 1 }}
                >
                  <HelpIcon />
                </Button>
              </Tooltip>
            </Typography>
            <Typography className="step-description">
              {'After creating a planner, you can test the planner here!'}
            </Typography>
          </Grid>
          <Grid xs={7} container className="project-details-container">
            <Grid xs={12} className="project-detail-input-container">
              <TextField
                className="project-detail-input"
                required
                autoFocus
                margin="dense"
                id="description"
                label="Sample Task"
                type="text"
                variant="outlined"
                value={taskDetails?.description || ''}
                name="description"
                onChange={handleTaskFormChange}
              />
            </Grid>
          </Grid>
          <Grid xs={5} container className="project-details-container">
            <Grid xs={12}>
              <Button
                className="step-button"
                variant="contained"
                color="success"
                onClick={createNewTask}
                sx={{ mt: 1, mr: 1 }}
                disabled={isLoading}
              >
                Test
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid container xs={12}>
          <Grid xs={12}>
            <Typography variant="h6" gutterBottom>
              Workflow
              <Tooltip
                title="Click for more info on how to create a workflow"
                arrow
              >
                <Button
                  onClick={() => {
                    setWebhookTutorialOpen(true);
                  }}
                  sx={{ mt: 1, mr: 1 }}
                >
                  <HelpIcon />
                </Button>
              </Tooltip>
            </Typography>
            <Typography className="step-description">
              {`Create a workflow URL and update it to the Project.`}
            </Typography>
          </Grid>
          <Grid xs={7} container className="project-details-container">
            <Grid xs={12} className="project-detail-input-container">
              <TextField
                className="project-detail-input"
                required
                autoFocus
                margin="dense"
                id="workflow"
                label="Workflow Url"
                placeholder="Paste the workflow URL that is copied..."
                type="text"
                variant="outlined"
                value={webHookDetails?.webhook || ''}
                name="webhook"
                onChange={handleWebHookFormChange}
              />
            </Grid>
          </Grid>
          <Grid xs={5} container className="project-details-container">
            <Grid xs={12}>
              <Button
                className="step-button"
                variant="contained"
                color="success"
                onClick={addWebHookToProject}
                sx={{ mt: 1, mr: 1 }}
                disabled={isWebHookLoading || isEmpty(webHookDetails.webhook)}
              >
                Add to Project
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    </Grid>
  );
};
