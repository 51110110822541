import {
  Stepper,
  Box,
  Step,
  StepLabel,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import SUserService from '../../services/user/user.service';
import { Fragment, SetStateAction, useEffect, useState } from 'react';
import SProjectService from '../../services/project/project.service';
import { IUserDetails } from 'types/users';

export default function DeleteProjectStepperDialog(props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  setRefresh: (value: boolean) => void;
  projectDetails: any;
}) {
  const { open, setOpen, setRefresh, projectDetails } = props;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const steps = ['Confirm Project', 'Confirm Identity', 'Delete Project'];
  const [userEmail, setUserEmail] = useState('');
  const [errorFlag, setErrorFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [associatedUsers, setAssociatedUsers] = useState<string[]>();
  const handleClose = () => {
    setRefresh(true);
    setOpen(false);
    setActiveStep(0);
    setUserEmail('');
    setAssociatedUsers(undefined);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const isStepOptional = (step: number) => {
    return step === -1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const authenticateUser = async (username: string) => {
    const user = await SUserService.getUserFromApi();
    return username === user.data.userPrincipalName;
  };

  const handleNext = async () => {
    setErrorFlag(false);
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep == 1) {
      const isAuthenticate = await authenticateUser(userEmail);
      if (!isAuthenticate) {
        setErrorFlag(true);
        return;
      }
    } else if (activeStep == steps.length - 1) {
      setIsLoading(true);
      SProjectService.deleteProject(projectDetails.id)
        .then(() => {
          setIsLoading(false);
          setOpenSnackbar(true);
          handleClose();
        })
        .catch((err) => {
          setIsLoading(false);
          window.alert(err);
          handleClose();
        });
    }
    if (activeStep != steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  useEffect(() => {
    if (associatedUsers) setAssociatedUsers(undefined);
    if (projectDetails.id) {
      SUserService.getUsersByProjectId(projectDetails.id)
        .then((response) => {
          const userEmails = response.map(
            (userdata: IUserDetails) => userdata.emailAddress
          );
          setAssociatedUsers(userEmails);
        })
        .catch((error) => {
          console.error('users fetch failed', error);
        });
    }
  }, [projectDetails]);

  const Fragment1 = (
    <div className="delete-project-fragment1-container">
      <div className="delete-project-fragment1-text">
        Project Id : {projectDetails.id}
      </div>
      <div className="delete-project-fragment1-text">
        Project Name : {projectDetails.name}
      </div>
      <div className="delete-project-fragment1-text">
        Project Description : {projectDetails.description}
      </div>
      <div className="delete-project-fragment1-text">
        Affected users :
        <div className="delete-project-fragment1-users">
          {associatedUsers ? (
            associatedUsers.length ? (
              associatedUsers.map((user, index) => {
                return <li key={index}>{user}</li>;
              })
            ) : (
              'No user Accounts Affected'
            )
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
      <div className="delete-project-fragment1-confirmation">
        Are you sure you want to continue?
      </div>
    </div>
  );

  const Fragment2 = (
    <div className="delete-project-username-container">
      <TextField
        helperText={
          errorFlag
            ? 'Wrong Email Address!'
            : 'Please confirm your Email Address!'
        }
        autoFocus
        margin="dense"
        label="Email Id"
        type="text"
        variant="outlined"
        value={userEmail}
        name="userEmail"
        onChange={(event: { target: { value: SetStateAction<string> } }) =>
          setUserEmail(event.target.value)
        }
        error={errorFlag}
      />
    </div>
  );

  const Fragment3 = (
    <div className="delete-project-username-container">
      <div className="delete-project-fragment1-confirmation">
        Please re-confirm to Delete the Project {projectDetails.name}!
      </div>
    </div>
  );

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity={'success'} sx={{ width: '100%' }}>
          {'Project is deleted successfully!'}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: 'delete-project-dialog-container' }}
      >
        <DialogTitle>Project Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                  error?: boolean;
                } = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            <Fragment>
              {activeStep == 0 && Fragment1}
              {activeStep == 1 && Fragment2}
              {activeStep == 2 && Fragment3}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  onClick={handleClose}
                  sx={{ mr: 1 }}
                  variant="contained"
                >
                  Cancel
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}
                <Button
                  onClick={handleNext}
                  variant="contained"
                  disabled={isLoading}
                >
                  {activeStep === steps.length - 1 ? (
                    isLoading ? (
                      <CircularProgress size={'20px'} />
                    ) : (
                      'Delete'
                    )
                  ) : (
                    'Continue'
                  )}
                </Button>
              </Box>
            </Fragment>
          </Box>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
}
