import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormControl, Grid } from '@mui/material';
import { matches } from 'lodash';
import SDriverFunctionService from 'services/driverFunction/driverFunction.service';
import {
  DriverFunctionData,
  DriverFunctionDataError,
} from 'interfaces/driverFunction.interface';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';

export default function AddDriverFunctionDialog(props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  driverFunctionData: DriverFunctionData;
  setRefreshFlag: Dispatch<SetStateAction<boolean>>;
}) {
  const { open, setOpen, driverFunctionData, setRefreshFlag } = props;
  const [driverFunctionDetailsError, setDriverFunctionDetailsError] =
    useState<DriverFunctionDataError>();
  const [driverFunctionDetails, setDriverFunctionDetails] =
    useState<DriverFunctionData>(driverFunctionData);

  useEffect(() => {
    if (driverFunctionData) setDriverFunctionDetails(driverFunctionData);
  }, [driverFunctionData]);

  const { showSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
    setDriverFunctionDetailsError({});
  };

  const driverFunctionInputValidator = () => {
    let error = {};
    if (driverFunctionDetails.type?.trim() === '') {
      error = {
        ...error,
        type: 'Name cannot be empty!',
      };
    }
    if (driverFunctionDetails.function?.trim() === '') {
      error = {
        ...error,
        function: 'Function cannot be empty!',
      };
    }
    setDriverFunctionDetailsError(error);
    return error;
  };

  const handleDriverFunctionDetailsChange = (key: string, value: string) => {
    setDriverFunctionDetails({
      ...driverFunctionDetails,
      [key]: value,
    });
  };

  const handleAddNewDriverFunction = () => {
    if (driverFunctionDetails) {
      const errors = driverFunctionInputValidator();
      if (matches(errors)({})) {
        try {
          if (driverFunctionData?.id) {
            const { id, ...updatedData } = driverFunctionDetails;
            SDriverFunctionService.updateDriverFunction(
              driverFunctionData.id,
              updatedData
            ).then(() => {
              setRefreshFlag(true);
              showSnackbar('Driver function update success', 'success');
            });
          } else
            SDriverFunctionService.createDriverFunction(
              driverFunctionDetails
            ).then(() => {
              setRefreshFlag(true);
              showSnackbar('Driver function added successfully', 'success');
            });
        } catch (error) {
          console.error(error);
          showSnackbar('Error occured, update failed', 'error');
        }
        setOpen(false);
      }
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Driver Function </DialogTitle>
        <DialogContent>
          <FormControl required sx={{ minWidth: 360, paddingTop: '1rem' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="type"
                  label="Type"
                  type="text"
                  helperText={driverFunctionDetailsError?.type || ''}
                  error={driverFunctionDetailsError?.type ? true : false}
                  required
                  value={driverFunctionDetails.type}
                  onChange={(e) =>
                    handleDriverFunctionDetailsChange('type', e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="name"
                  label="Name"
                  type="text"
                  value={driverFunctionDetails.name}
                  onChange={(e) =>
                    handleDriverFunctionDetailsChange('name', e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="deviceId"
                  label="Device ID"
                  type="text"
                  value={driverFunctionDetails.deviceId}
                  onChange={(e) =>
                    handleDriverFunctionDetailsChange(
                      'deviceId',
                      e.target.value
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="sensor"
                  label="Sensor Name"
                  type="text"
                  value={driverFunctionDetails.sensor}
                  onChange={(e) =>
                    handleDriverFunctionDetailsChange('sensor', e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id="function"
                  label="Function url"
                  type="text"
                  helperText={driverFunctionDetailsError?.function || ''}
                  error={driverFunctionDetailsError?.function ? true : false}
                  required
                  value={driverFunctionDetails.function}
                  onChange={(e) =>
                    handleDriverFunctionDetailsChange(
                      'function',
                      e.target.value
                    )
                  }
                />
              </Grid>
            </Grid>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleAddNewDriverFunction}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
