import { Alert, Button, FormControl, Snackbar } from '@mui/material';
import SProjectService from '../../services/project/project.service';
import SLocationService from '../../services/location/location.service';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import './Location.scss';
import AddLocationDialog from './AddLocationDialog';
import ListLocations from './ListLocations';
import ProjectAutocomplete from '../../components/ProjectAutocomplete/ProjectAutocomplete';
import { IProjectDetails } from 'interfaces/project.interface';
import { ILocationDetails } from 'types/location';

const Location: React.FunctionComponent = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [projectLocationType, setProjectLocationType] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [openNewLocation, setOpenNewLocation] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [refresh, setRefresh] = useState(false);
  const [projectsList, setProjectsList] = useState<IProjectDetails[]>([]);
  const [locationList, setLocationList] = useState<ILocationDetails[]>([]);
  const [disable, setDisabled] = useState(true);
  const fetchAllLocations = async (selectedProjectId: string) => {
    await SLocationService.findLocationNames(selectedProjectId)
      .then((response) => {
        setLocationList(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onSelectedProjectChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: { name: string; id: string }
  ) => {
    setSelectedProject(newValue?.id);
    fetchAllLocations(newValue?.id);
    setDisabled(false);
    setLocationList([]);
  };
  const sortProjectsByName = (projectName: any[]) => {
    return projectName.slice().sort((a, b) => a.name.localeCompare(b.name));
  };

  useEffect(() => {
    SProjectService.findNames()
      .then((response) => {
        setProjectsList(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleOpenNewLocationDialog = () => {
    setOpenNewLocation(true);
  };
  const handleDeletelocation = (id: string): void => {
    SLocationService.deleteLocation(id)
      .then(() => {
        fetchAllLocations(selectedProject);
        setDeleteSuccess(true);
        setStatusOpen(true);
      })
      .catch((error) => {
        alert(error.message);
        setStatusOpen(true);
      });
  };

  const handleDeleteClose = () => {
    setStatusOpen(false);
    return;
  };

  const displayLocation = () => {
    fetchAllLocations(selectedProject);
  };

  const buttonColor = disable ? '#D3D3D3' : '#234C8A';
  return (
    <div className="location-main-container">
      <div className="location-details-title"> Location</div>
      <div className="project-select-main-conatiner">
        <div className="project-select-location">
          <FormControl required sx={{ mb: 5, mt: 5, minWidth: 300 }}>
            {projectsList.length > 0 && (
              <ProjectAutocomplete
                projectsList={sortProjectsByName(projectsList)}
                onSelectedProjectChange={onSelectedProjectChange}
              />
            )}
          </FormControl>
        </div>
      </div>
      <ListLocations
        selectedProject={selectedProject}
        fetchAllLocations={fetchAllLocations}
        locationsList={locationList}
        handleDeletelocation={handleDeletelocation}
      />
      <div className="new-location-button-container-space">
        <div className="new-location-button-container">
          {
            <Button
              className="new-location-button"
              sx={{
                backgroundColor: buttonColor,
                color: '#ffff',
                fontFamily: 'Inria Sans',
                '&:hover': {
                  backgroundColor: '#ffff',
                  color: '#234c8a',
                },
              }}
              variant="outlined"
              onClick={handleOpenNewLocationDialog}
              disabled={disable}
            >
              Add Location
            </Button>
          }
        </div>
      </div>
      <AddLocationDialog
        open={openNewLocation}
        setOpen={setOpenNewLocation}
        setRefresh={setRefresh}
        projectId={selectedProject}
        displayLocation={displayLocation}
      ></AddLocationDialog>
      <Snackbar
        open={statusOpen}
        autoHideDuration={6000}
        onClose={handleDeleteClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <Alert
          onClose={handleDeleteClose}
          severity={deleteSuccess ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {deleteSuccess ? 'Deletion Successful' : 'Deletion  Failed'}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Location;
