import { tenantId } from 'authConfig';

export const getInformationBarrierScript = (
  segmentName: string,
  policyName: string,
  attributeName: string,
  attributeValue: string,
  userPrincipalName: string
) => {
  const powershellScript = `
# Log in to Azure
az login --tenant ${tenantId}
Connect-IPPSSession -UserPrincipalName ${userPrincipalName}
# Create a new organization segment
New-OrganizationSegment -Name "${segmentName} segment" -UserGroupFilter "${attributeName} -eq '${attributeValue}'"
# Create a new organization policy
New-InformationBarrierPolicy -Name "${policyName} policy" -AssignedSegment "${segmentName}" -SegmentsAllowed "${segmentName}" -State Active
# Starting the Information Barrier Policy.  Allow 30 minutes for the system to start applying the policies...
Start-InformationBarrierPoliciesApplication
`;
  return powershellScript;
};
