const AZURE_AD_SCOPE = 'b12c3523-6472-444b-a90b-eca1bc65df11/default';
export const commonConstants = {
  AZURE_AD_SCOPE,
};

export const emailExpression =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const cibusCellDomainValidator =
  /^[a-zA-Z0-9._%+-]+@(?:cibuscell\.com)$/;

export enum OGEDigitizationLevel {
  CLow = 'C-low',
  BMedium = 'B-medium',
  Ahigh = 'A-high',
}
