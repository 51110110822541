import { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import ProjectAutocomplete from 'components/ProjectAutocomplete/ProjectAutocomplete';
import { IProjectInfo } from 'interfaces/project.interface';
import { IProjectMini } from 'types/project';
import SLocationService from 'services/location/location.service';
import SProjectService from 'services/project/project.service';
import SUserService from 'services/user/user.service';
import { IUserDetails } from 'types/users';
import NewUserDialog from 'pages/Users/components/New-User-Dialog';
import { TeamsFragment } from 'pages/Teams/TeamsSetup';
import { getInformationBarrierScript } from 'utils/informationBarrier.utils';
import { mapDomain } from 'constants/user';
import { TeamsFormData } from 'interfaces/teams.interface';
import { ILocationDetails } from 'types/location';

export interface CurrentTeamsDetails {
  teamsName: string | undefined;
  channelName: string | undefined;
}

const Teams: FunctionComponent = () => {
  const [newUserDialogue, setNewUserDialogue] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState<
    string | undefined
  >();
  const [locationList, setLocationList] = useState<ILocationDetails[]>([]);
  const [userAADObjectId, setUserAADObjectId] = useState<string | undefined>();
  const [isTeamsCreated, setIsTeamsCreated] = useState(false);
  const [script, setScript] = useState<string | undefined>();
  const [isCopied, setIsCopied] = useState(false);
  const [projectsList, setProjectsList] = useState<IProjectInfo[]>([]);
  const [selectedProject, setSelectedProject] = useState<IProjectMini | null>();
  const [usersList, setUsersList] = useState<IUserDetails[]>([]);
  const [userPrincipalName, setUserPrincipalName] = useState<
    string | undefined
  >();
  const [currentTeamsData, setCurrentTeamsData] = useState<
    CurrentTeamsDetails | undefined
  >();

  const [teamsFormData, setTeamsFormData] = useState<TeamsFormData>({
    teamsDetails: {
      teamsName: '',
      channelName: '',
      teamsDescription: '',
      channelDescription: '',
      teamsId: '',
      channelId: '',
      notificationLink: '',
    },
    memberAADObjectId: '',
    ownerAADObjectId: '',
    projectId: '',
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    SUserService.getUser().then((response: any) => {
      setUserPrincipalName(response.userPrincipalName);
    });
    SProjectService.findNames()
      .then((response) => {
        setProjectsList(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (
      isTeamsCreated &&
      teamsFormData.teamsDetails.teamsName &&
      userPrincipalName
    ) {
      const originalTeamsName = teamsFormData.teamsDetails.teamsName;
      setScript(
        getInformationBarrierScript(
          originalTeamsName,
          originalTeamsName,
          'MemberOf',
          originalTeamsName.replace(/\s+/g, '') + mapDomain[0].value,
          userPrincipalName
        )
      );
    }
  }, [isTeamsCreated]);

  const handleSelectLocation = async (e: SelectChangeEvent<string>) => {
    const locationSelected = locationList.find(
      (location) => location.id === e.target.value
    );
    if (locationSelected) {
      setSelectedLocationId(locationSelected.id);
      const locationDetails = await SLocationService.getLocationDetails(
        locationSelected.id
      );
      if (locationDetails.MSTeamsName && locationDetails.MSTeamsChannel) {
        setCurrentTeamsData({
          teamsName: locationDetails?.MSTeamsName,
          channelName: locationDetails?.MSTeamsChannel,
        });
      }
    }
  };

  const handleSelectUser = (e: SelectChangeEvent<string>) => {
    const userSelected = usersList.find((user) => user.id === e.target.value);
    if (userSelected?.aadObjectId) setUserAADObjectId(userSelected.aadObjectId);
  };

  const fetchAllUsers = (projectId: string) => {
    SUserService.getUsersByProjectId(projectId)
      .then((response) => {
        setUsersList(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleProjectChange = (
    e: SyntheticEvent<Element, Event>,
    project: IProjectMini | null
  ) => {
    setCurrentTeamsData(undefined);
    setSelectedLocationId(undefined);
    setLocationList([]);
    setIsTeamsCreated(false);
    setUserAADObjectId(undefined);
    if (project?.id) {
      fetchAllUsers(project.id);
      SLocationService.findLocationNames(project.id).then(async (locations) => {
        if (locations.length) {
          setLocationList(locations);
        }
      });
    }
    setSelectedProject(project);
  };

  const handleCopyClick = () => {
    if (script) {
      navigator.clipboard.writeText(script);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };

  return (
    <div>
      <Grid container spacing={2} className="main-grid-container">
        <Grid item xs={12}>
          Teams Creation & Updation
        </Grid>
        <Grid item xs={12}>
          <FormControl required sx={{ mb: 5, minWidth: 360 }}>
            {projectsList?.length ? (
              <ProjectAutocomplete
                projectsList={projectsList}
                onSelectedProjectChange={handleProjectChange}
              />
            ) : (
              'No projects present'
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {selectedProject && (
            <FormControl required sx={{ mb: 5, minWidth: 360 }}>
              <InputLabel id="location-select-label">Location</InputLabel>
              <Select
                labelId="location-select-label"
                id="location-select"
                label="Location"
                defaultValue={''}
                onChange={handleSelectLocation}
              >
                {locationList?.map((location) => (
                  <MenuItem key={location.id} value={location.id}>
                    {location.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        {selectedProject && selectedLocationId && (
          <>
            <Grid item xs={12}>
              {usersList?.length ? (
                <FormControl required sx={{ mb: 5, minWidth: 360 }}>
                  <InputLabel id="user-select-label">User</InputLabel>
                  <Select
                    labelId="user-select-label"
                    id="user-select"
                    label="User"
                    defaultValue={''}
                    onChange={handleSelectUser}
                  >
                    {usersList?.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        <div className="user-select-menu-items">
                          {user.emailAddress}
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => setNewUserDialogue(true)}
                >
                  Add User
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              {currentTeamsData && !isTeamsCreated && (
                <Typography>
                  <h3>Current Teams Details</h3>

                  <span>Teams Name : {currentTeamsData.teamsName}</span>
                  <br />
                  <span>Channel Name : {currentTeamsData.channelName}</span>
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              {userAADObjectId && (
                <>
                  <TeamsFragment
                    formData={teamsFormData}
                    setFormData={setTeamsFormData}
                    setTeamsCreated={setIsTeamsCreated}
                    projectId={selectedProject.id}
                    locationId={selectedLocationId}
                    userAADObjectId={userAADObjectId}
                  />
                  <Paper square elevation={0} sx={{ p: 3 }}>
                    {isTeamsCreated && (
                      <Typography>
                        Please add the Barrier Policies. Open your Windows
                        PowerShell in Administrator Mode and copy and run the
                        following code snippet.
                        <div className="code-snippet-container">
                          <code className="code-snippet-code">{script}</code>
                          <div className="copy-button-container">
                            <button
                              className="copy-button"
                              onClick={handleCopyClick}
                            >
                              {isCopied ? 'Copied!' : 'Copy to Clipboard'}
                            </button>
                          </div>
                        </div>
                      </Typography>
                    )}
                  </Paper>
                </>
              )}
            </Grid>
            <NewUserDialog
              open={newUserDialogue}
              projectId={selectedProject.id}
              projectName={selectedProject.name}
              handleClose={() => setNewUserDialogue(false)}
              refreshUsersList={() => {
                fetchAllUsers(selectedProject.id);
              }}
            />
          </>
        )}
      </Grid>
    </div>
  );
};

export default Teams;
