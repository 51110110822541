import {
  LocationOn,
  Person,
  Description,
  Dashboard,
  Factory,
  AppSettingsAlt,
  PermPhoneMsg,
} from '@mui/icons-material';

export const SidebarData = [
  {
    title: 'Project',
    path: '/',
    icon: <Factory />,
  },
  {
    title: 'Location',
    path: '/location',
    icon: <LocationOn />,
  },
  {
    title: 'Users',
    path: '/users',
    icon: <Person />,
  },
  {
    title: 'Reports',
    path: '/reports',
    icon: <Description />,
  },
  {
    title: 'ADX Dashboard',
    path: '/adxdashboard',
    icon: <Dashboard />,
  },
  {
    title: 'Menu Tab',
    path: '/menu',
    icon: <AppSettingsAlt />,
  },
  {
    title: 'Driver Function',
    path: '/driverfunction',
    icon: <AppSettingsAlt />,
  },
  {
    title: 'Teams',
    path: '/teams',
    icon: <PermPhoneMsg />,
  },
];
