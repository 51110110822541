import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  TablePagination,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import './ListLocation.scss';
import { FaEdit } from 'react-icons/fa';
import { useCallback, useEffect, useState } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import styled from 'styled-components';
import { ILocationDetails } from 'types/location';
import SUserService from '../../services/user/user.service';
import SLocationService from '../../services/location/location.service';
import SGridDataService from '../../services/gridData/gridData.service';
import { OGEDigitizationLevel } from 'constants/common';

const TableWrapper = styled.p`
  height: 400;
  width: '50%';
  margin-top: 20px;
  font-family: 'Inria Sans';
`;

interface IListlocations {
  selectedProject: string;
  fetchAllLocations: (selectedProjectId: string) => Promise<void>;
  locationsList: ILocationDetails[];
  handleDeletelocation: (id: string) => void;
}

const Listlocations = (props: IListlocations) => {
  const {
    selectedProject,
    fetchAllLocations,
    locationsList,
    handleDeletelocation,
  } = props;
  const [open, setOpen] = useState(false);
  const [openVerified, setOpenVerified] = useState(false);
  const [selectedlocationId, setSelectedlocationId] = useState('');
  const [usernameVerification, setUsernameVerification] = useState('');
  const [currentUser, setCurrentUser] = useState({ mail: '' });
  const [invalidUserName, setInvalidUserName] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [singleLocation, setSingleLocation] = useState(false);
  const [editLocation, setEditLocation] = useState(false);
  const [deleteGridDataCheckbox, setDeleteGridDataCheckbox] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<string>();
  const [gridDataDeleteFlag, setGridDataDeleteFlag] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateSnackbarOpen, setUpdateSnackbarOpen] = useState(false);
  const [newlocationData, setNewLocationData] =
    useState<Partial<ILocationDetails | undefined>>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setLoading] = useState(false);
  const [locationNameError, setLocationNameError] = useState(false);
  const [cOGELevelError, setCOGELevelError] = useState('');

  const handleClickOpen = (locationId: string) => {
    setSelectedlocationId(locationId);
    setOpen(true);
  };
  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpenVerified = () => {
    if (currentUser.mail === usernameVerification) {
      setOpenVerified(true);
    } else {
      setInvalidUserName(true);
      setStatusOpen(true);
    }
  };

  const handleCloseEdit = () => {
    setLoading(false);
    setEditLocation(false);
    setLocationNameError(false);
    setCOGELevelError('');
    setGridDataDeleteFlag(false);
    setDeleteGridDataCheckbox(false);
  };

  const handleUpdateLocation = async () => {
    if (newlocationData?.name?.trim().length) {
      // Setting OGEDigitalization Level as mandatory
      if (!newlocationData?.cOGEDigitizationLevel?.trim().length) {
        setCOGELevelError('OGEDigitalization Level cannot be empty!');
        return;
      }
      setLocationNameError(false);
      if (deleteGridDataCheckbox) {
        setDeleteGridDataCheckbox(false);
        setGridDataDeleteFlag(true);
      } else {
        if (selectedLocation) {
          if (newlocationData?.id) {
            setLoading(true);
            const { id, ...restData } = newlocationData;
            await SLocationService.updateLocationDetails(
              selectedLocation,
              restData
            )
              .then(() => {
                setUpdateSuccess(true);
                fetchAllLocations(selectedProject);
                setUpdateSnackbarOpen(true);
              })
              .catch((error) => {
                setUpdateSuccess(false);
                setUpdateSnackbarOpen(false);
                console.log(error);
              })
              .finally(() => {
                setLoading(false);
                setEditLocation(false);
                setGridDataDeleteFlag(false);
              });
          }
        }
      }
    } else {
      setLocationNameError(true);
    }
  };

  const handleDeleteOldgridData = async () => {
    setLoading(true);
    if (selectedLocation) {
      await SGridDataService.deleteGridDataRows(selectedLocation)
        .then(() => {
          handleUpdateLocation();
        })
        .catch((error) => {
          setLoading(false);
          setEditLocation(false);
          setGridDataDeleteFlag(false);
          setUpdateSuccess(false);
          setUpdateSnackbarOpen(false);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onEditLocation = (value: number | string, key: string) => {
    setNewLocationData({ ...newlocationData, [key]: value });
  };

  const handleClose = () => {
    setSelectedlocationId('');
    setUsernameVerification('');
    setStatusOpen(false);
    setOpen(false);
  };
  const handleCloseVerified = () => {
    setSelectedlocationId('');
    setUsernameVerification('');
    setOpenVerified(false);
    handleClose();
  };

  const onDeleteConfirm = useCallback(() => {
    handleDeletelocation(selectedlocationId);
    handleCloseVerified();
  }, [handleDeletelocation, selectedlocationId]);

  const handleStatusClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason && reason === 'clickaway') {
      setStatusOpen(false);
      return;
    }
  };

  const currentLocationDetails = async (locationId: string) => {
    setSelectedLocation(locationId);
    const locationData = await SLocationService.getLocationDetails(locationId);
    setNewLocationData(locationData);
  };

  const handleOpenAlert = () => {
    setSingleLocation(true);
  };

  const handleSingleLocationAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway' || !reason) {
      setSingleLocation(false);
      return;
    }
  };

  const handleUpdateAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setUpdateSnackbarOpen(false);
  };

  useEffect(() => {
    SUserService.getUser().then((user: any) => {
      setCurrentUser(user);
    });
  }, []);
  return (
    <>
      {locationsList.length ? (
        <TableWrapper>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow selected>
                  <TableCell align="left" sx={{ fontFamily: 'Inria Sans' }}>
                    Id
                  </TableCell>
                  <TableCell align="left" sx={{ fontFamily: 'Inria Sans' }}>
                    Name
                  </TableCell>
                  <TableCell align="left" sx={{ fontFamily: 'Inria Sans' }}>
                    Description
                  </TableCell>
                  <TableCell align="left" sx={{ fontFamily: 'Inria Sans' }}>
                    Address
                  </TableCell>
                  <TableCell align="center" sx={{ fontFamily: 'Inria Sans' }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? locationsList.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : locationsList
                ).map((location) => (
                  <TableRow
                    key={location.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left" sx={{ fontFamily: 'Inria Sans' }}>
                      {location.id}
                    </TableCell>
                    <TableCell align="left" sx={{ fontFamily: 'Inria Sans' }}>
                      {location.name}
                    </TableCell>
                    <TableCell align="left" sx={{ fontFamily: 'Inria Sans' }}>
                      {location.description}
                    </TableCell>
                    <TableCell align="left" sx={{ fontFamily: 'Inria Sans' }}>
                      {location.address}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => {
                            if (locationsList.length === 1) {
                              handleOpenAlert();
                            } else {
                              handleClickOpen(location.id.toString());
                            }
                          }}
                        >
                          <FaRegTrashAlt color="red" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() => {
                            setEditLocation(true);
                            currentLocationDetails(location.id.toString());
                          }}
                        >
                          <FaEdit color="blue" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 30]}
              component="div"
              count={locationsList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Delete location</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Verify your email to proceed
              </DialogContentText>
              <TextField
                size="medium"
                type="string"
                value={usernameVerification}
                onChange={(event) => {
                  setUsernameVerification(event.target.value);
                }}
                inputProps={{
                  style: {
                    border: ' 0.5px solid #5F9CD9',
                    padding: '5px 14px',
                  },
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button color="error" onClick={handleClickOpenVerified} autoFocus>
                Verify
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openVerified}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Delete Location</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you want to delete this location permanently ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseVerified}>Cancel</Button>
              <Button color="error" onClick={onDeleteConfirm} autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </TableWrapper>
      ) : null}
      <Dialog
        open={editLocation}
        onClose={handleCloseEdit}
        className="dialog-location"
      >
        <DialogTitle>
          <div className="title-location">{'Edit Location Data'}</div>
        </DialogTitle>
        <DialogContent className="dialogue-wrapper">
          <TextField
            type="text"
            label="Location Name"
            required
            value={newlocationData?.name ? newlocationData?.name : ''}
            onChange={(event) => {
              onEditLocation(event.target.value, 'name');
            }}
            sx={{ marginBottom: '40px' }}
            helperText={
              locationNameError ? 'Location Name cannot be empty' : ''
            }
            error={locationNameError}
          />
          <FormControl required sx={{ mb: 5, minWidth: 250 }}>
            <InputLabel id="OGEDigitization-label">OGEDigitization</InputLabel>
            <Select
              labelId="OGEDigitization-label"
              id="OGEDigitization-select"
              size="medium"
              required
              sx={{ fontFamily: 'Inria Sans' }}
              label="OGEDigitization"
              value={
                newlocationData?.cOGEDigitizationLevel
                  ? newlocationData?.cOGEDigitizationLevel
                  : ''
              }
              onChange={(event) => {
                setCOGELevelError('');
                onEditLocation(event.target.value, 'cOGEDigitizationLevel');
              }}
            >
              {Object.values(OGEDigitizationLevel)?.map((element) => {
                return (
                  <MenuItem key={element} value={element}>
                    {element}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText sx={{ color: '#d32f2f' }}>
              {cOGELevelError}
            </FormHelperText>
          </FormControl>

          <TextField
            type="text"
            label="Web Hook URL"
            value={
              newlocationData?.connectorUrl ? newlocationData?.connectorUrl : ''
            }
            onChange={(event) => {
              onEditLocation(event.target.value, 'connectorUrl');
            }}
            sx={{ marginBottom: '40px' }}
          />
          <label className="checkbox-wrap">
            <input
              type="checkbox"
              checked={deleteGridDataCheckbox}
              onChange={() => {
                setDeleteGridDataCheckbox(!deleteGridDataCheckbox);
              }}
            />
            Delete Old Grid Data
          </label>
        </DialogContent>
        <DialogActions sx={{ paddingTop: '50px' }}>
          <div className="button-wrapper">
            <Button onClick={handleCloseEdit}>Cancel</Button>
            <Button
              onClick={handleUpdateLocation}
              variant="contained"
              className="update-location-button"
            >
              {isLoading ? (
                <CircularProgress size={'20px'} color="inherit" />
              ) : (
                'Update Location Details'
              )}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog open={gridDataDeleteFlag} onClose={handleCloseEdit}>
        <DialogTitle>{'Old Grid Data Deletion'}</DialogTitle>
        <DialogContent>
          Do you really want to delete this old grid data ?
        </DialogContent>
        <DialogActions sx={{ paddingTop: '50px' }}>
          <div className="button-wrapper">
            <Button onClick={handleCloseEdit}>No</Button>
            <Button onClick={handleDeleteOldgridData} variant="contained">
              {isLoading ? (
                <CircularProgress size={'20px'} color="inherit" />
              ) : (
                'Yes'
              )}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={statusOpen}
        autoHideDuration={1000}
        onClose={handleStatusClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <Alert
          onClose={handleStatusClose}
          severity={!invalidUserName ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {invalidUserName ? 'Email entered is invalid.' : ''}
        </Alert>
      </Snackbar>
      <Snackbar
        open={singleLocation}
        autoHideDuration={4000}
        onClose={handleSingleLocationAlertClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <Alert
          onClose={handleSingleLocationAlertClose}
          severity={!singleLocation ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {singleLocation
            ? 'Selected Location cannot be deleted as there are no other locations associated with the project'
            : ''}
        </Alert>
      </Snackbar>
      <Snackbar
        open={updateSnackbarOpen}
        autoHideDuration={4000}
        onClose={handleUpdateAlertClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <Alert
          onClose={handleUpdateAlertClose}
          severity={updateSuccess ? 'success' : 'error'}
        >
          {updateSuccess
            ? 'Location Data updated successfully'
            : 'Location update failed'}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Listlocations;
