export const mapDomain = [{ value: '@cibuscell.com', label: '@cibuscell.com' }];

export const InitialUserDetails = {
  id: '',
  description: '',
  avatar: '',
  emailAddress: '',
  isAdmin: false,
  isLocked: false,
  isSuperUser: false,
  menuConfiguration: '',
  aadObjectId: '',
  secondaryEmail: '',
  projectId: null,
  lastChosenLocation: null,
};
