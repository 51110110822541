import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { FaEdit } from 'react-icons/fa';

import { IMenuItem, MenuItemStatus, MenuItemTypes } from 'types/menu';

import { MenuContainer, SaveButton, TableWrapper } from './style';
import SProjectService from '../../services/project/project.service';
import SUserService from '../../services/user/user.service';
import { IUserDetails } from 'types/users';
import ProjectAutocomplete from '../../components/ProjectAutocomplete/ProjectAutocomplete';

const Menu: React.FunctionComponent = () => {
  const [projectsList, setProjectsList] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [isProjectSpecific, setIsProjectSpecific] = useState(false);
  const [usersList, setUsersList] = useState<IUserDetails[]>([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [menuConfiguration, setMenuConfiguration] = useState<any>([]);
  const [selectedMenuType, setSelectedMenuType] = useState('');
  const [selectedMenuStatus, setSelectedMenuStatus] = useState('');
  const [selectedMenuText, setSelectedMenuText] = useState('');

  //   useEffect to fetch names of all projects on page load
  useEffect(() => {
    SProjectService.findNames()
      .then((response) => {
        setProjectsList(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // Fetching users of selected project
  const fetchAllUsers = (projectId: string) => {
    SUserService.getUsersByProjectId(projectId)
      .then((response) => {
        setUsersList(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Fetching project level menu config if isProjectSpecific flag is true
  //  else,Fetching users of selected project on projectId change
  useEffect(() => {
    if (selectedProject) {
      setMenuConfiguration([]);
      if (!isProjectSpecific) {
        fetchAllUsers(selectedProject);
      } else {
        SProjectService.getMenuConfiguration(selectedProject).then((res) => {
          const menuConfig = res?.menuConfiguration
            ? JSON.parse(res?.menuConfiguration.toString())
            : [];
          setMenuConfiguration(menuConfig);
        });
      }
    }
  }, [selectedProject, isProjectSpecific]);

  // Filtering existing menu configuration of a user from usersList
  useEffect(() => {
    if (selectedUser && usersList.length) {
      const userDetails = usersList.find((user) => user?.id === selectedUser);
      const menuConfig = userDetails?.menuConfiguration
        ? JSON.parse(userDetails?.menuConfiguration.toString())
        : [];
      setMenuConfiguration(menuConfig);
    }
  }, [selectedUser]);

  // use effect to clear status and text when type changes
  useEffect(() => {
    if (menuConfiguration.length > 0) {
      const menuValue = menuConfiguration.find(
        (item: IMenuItem) => item.type === selectedMenuType
      );
      if (menuValue) {
        setSelectedMenuStatus(menuValue.status);
        setSelectedMenuText(menuValue.text);
      } else {
        setSelectedMenuStatus('');
        setSelectedMenuText('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenuType]);

  const onSelectedProjectChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: { name: string; id: string }
  ) => {
    setSelectedProject(newValue?.id);
  };
  const onSelectedUserChange = (e: any) => {
    setSelectedUser(e.target.value);
  };

  const onMenuTypeChange = (e: any) => {
    setSelectedMenuType(e.target.value);
  };
  const onMenuStatusChange = (e: any) => {
    setSelectedMenuStatus(e.target.value);
  };
  const onMenuTextChange = (e: any) => {
    setSelectedMenuText(e.target.value);
  };

  const onProjectSpecificChange = (e: any) => {
    const checked = e.target.checked;
    if (checked) {
      setSelectedUser('');
    }
    setIsProjectSpecific(checked);
  };

  const onMenuItemEdit = (menuItem: IMenuItem) => {
    setSelectedMenuType(menuItem.type);
    setSelectedMenuStatus(menuItem.status);
    setSelectedMenuText(menuItem.text);
  };

  // Function to add/update menu item configuration
  const handleAddMenuConfig = () => {
    if (!!selectedMenuType && !!selectedMenuStatus && !!selectedProject) {
      const selectedMenuConfig = {
        type: selectedMenuType,
        status: selectedMenuStatus,
        text: selectedMenuText,
      };

      const menuIndex = menuConfiguration.findIndex(
        (item: { type: string }) => item.type === selectedMenuConfig.type
      );
      if (menuIndex !== -1) {
        const updatedItems = menuConfiguration.map((item: { type: string }) =>
          item.type === selectedMenuConfig.type ? selectedMenuConfig : item
        );
        setMenuConfiguration(updatedItems);
      } else {
        setMenuConfiguration((prev: any) => {
          return [...prev, selectedMenuConfig];
        });
      }
      setSelectedMenuType('');
      setSelectedMenuStatus('');
      setSelectedMenuText('');
    }
  };

  // Saving menu config to user/project table according to the value of isProjectSpecific
  const handleSaveMenuConfiguration = () => {
    if (isProjectSpecific) {
      SProjectService.updateProjectDetails(
        {
          menuConfiguration: JSON.stringify(menuConfiguration),
        },
        selectedProject
      ).then(() => {
        alert('Menu Configuration successfully saved.');
      });
    } else {
      SUserService.updateUserDetails(
        {
          projectId: selectedProject,
          menuConfiguration: JSON.stringify(menuConfiguration),
        },
        selectedUser
      ).then(() => {
        alert('Menu Configuration successfully saved.');
      });
    }
  };

  const menuTypes = Object.keys(MenuItemTypes);
  const menuStatusTypes = Object.keys(MenuItemStatus);

  return (
    <MenuContainer>
      <>
        <Typography variant="h5" sx={{ mb: 4, color: 'blue' }}>
          Menu Tab Configuration
        </Typography>
        <FormControl required sx={{ mb: 2, minWidth: 360 }}>
          <ProjectAutocomplete
            projectsList={projectsList}
            sx={{ width: 360 }}
            onSelectedProjectChange={onSelectedProjectChange}
          />
        </FormControl>
        <FormControlLabel
          sx={{ mb: 2 }}
          control={
            <Checkbox
              checked={isProjectSpecific}
              onChange={onProjectSpecificChange}
            />
          }
          label="At Project Level"
        />
        <FormControl required sx={{ mb: 5, minWidth: 360 }}>
          <InputLabel id="user-select-label">User</InputLabel>
          <Select
            labelId="user-label"
            id="user-select"
            size="medium"
            label="User"
            color="success"
            value={selectedUser}
            disabled={isProjectSpecific}
            onChange={onSelectedUserChange}
          >
            {usersList?.map((user: { id: string; emailAddress: string }) => {
              return (
                <MenuItem key={user.id} value={user.id}>
                  <div className="user-select-menu-items">
                    {user.emailAddress}
                  </div>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl required sx={{ mb: 5, minWidth: 360 }}>
          <InputLabel id="report-select-label">Menu Type</InputLabel>
          <Select
            labelId="report-type-label"
            id="report-type-select"
            label="Menu Type"
            size="medium"
            value={selectedMenuType}
            onChange={onMenuTypeChange}
          >
            {menuTypes?.map((type: string, index: number) => {
              return (
                <MenuItem key={index} value={type}>
                  <div className="project-select-menu-items">{type}</div>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl required sx={{ mb: 5, minWidth: 360 }}>
          <InputLabel id="report-select-label">Status</InputLabel>
          <Select
            labelId="report-type-label"
            id="report-type-select"
            size="medium"
            value={selectedMenuStatus}
            onChange={onMenuStatusChange}
          >
            {menuStatusTypes?.map((type: string, index: number) => {
              return (
                <MenuItem key={index} value={type}>
                  <div className="project-select-menu-items">{type}</div>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 360 }}>
          <TextField
            size="medium"
            disabled={
              selectedMenuStatus === 'HIDE' || selectedMenuStatus === 'INACTIVE'
            }
            label="Menu Text"
            type="string"
            value={selectedMenuText}
            onChange={onMenuTextChange}
            inputProps={{
              maxLength: 100,
            }}
          />
        </FormControl>
      </>
      <SaveButton>
        <Button
          variant="contained"
          onClick={handleAddMenuConfig}
          disabled={
            !selectedProject || !selectedMenuStatus || !selectedMenuType
          }
        >
          Add to List
        </Button>
      </SaveButton>
      {menuConfiguration.length ? (
        <>
          <TableWrapper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Text</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {menuConfiguration.map((menuItem: IMenuItem) => (
                    <TableRow
                      key={menuItem.type}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">{menuItem.type}</TableCell>
                      <TableCell align="left">{menuItem.status}</TableCell>
                      <TableCell align="left">{menuItem.text}</TableCell>
                      <TableCell align="left">
                        <IconButton
                          onClick={() => {
                            onMenuItemEdit(menuItem);
                          }}
                        >
                          <FaEdit color="blue" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TableWrapper>

          <SaveButton>
            <Button
              variant="contained"
              color="success"
              onClick={handleSaveMenuConfiguration}
            >
              Save Configuration
            </Button>
          </SaveButton>
        </>
      ) : null}
    </MenuContainer>
  );
};

export default Menu;
