/*******************************************************
Copyright (C) 2021 CibusCell Technology GmbH - contact@cibuscell.com , Inc - All Rights Reserved
Proprietary and confidential.
Unauthorized copying of this file, via any medium is strictly prohibited.
Written by Silpa Sivadas, silpa.sivadas@cibuscell.com, January 2024
*******************************************************/
import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import './Teams.scss';
import {
  ChangeEvent,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import STeamsService from 'services/teams/teams.service';
import SUserService from 'services/user/user.service';
import { isEmpty } from 'lodash';
import { TeamsSettingsFragment } from './TeamsSettingsSetup';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';
import { TeamsDetails, TeamsFormData } from 'interfaces/teams.interface';
import { IUserDetails } from 'types/users';
interface ITeamsSetupProps {
  projectId: string;
  locationId: string;
  userAADObjectId: string;
  setTeamsCreated: Dispatch<SetStateAction<boolean>>;
  projectType?: number;
  formData: TeamsFormData;
  setFormData: Dispatch<SetStateAction<TeamsFormData>>;
  userName?: string;
}
export const TeamsFragment: FunctionComponent<ITeamsSetupProps> = (props) => {
  const {
    projectId,
    locationId,
    userAADObjectId,
    setTeamsCreated,
    projectType,
    formData,
    setFormData,
    userName,
  } = props;

  const [isLoading, setLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [memberAdditionError, setMemberAdditionError] = useState<null | string>(
    null
  );
  const [isCreateTeamsButtonEnabled, setCreateTeamsButtonEnabled] =
    useState(false);
  useEffect(() => {
    SUserService.getUserDetails().then((userDetailsResponse: IUserDetails) => {
      if (userDetailsResponse)
        setFormData((prevState) => ({
          ...prevState,
          ownerAADObjectId: userDetailsResponse.aadObjectId,
          projectId: projectId.toString(),
          memberAADObjectId: userAADObjectId,
        }));
    });
  }, []);

  const handleFormChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const name = event.target.name;
    const value = event.target.value;

    const updatedFormData = {
      ...formData,
      teamsDetails: {
        ...formData.teamsDetails,
        [name]: value,
      },
    };
    setFormData(updatedFormData);
  };

  const createNewTeams = () => {
    setLoading(true);
    STeamsService.createNewTeams(formData)
      .then((teamsInfo: TeamsDetails) => {
        setLoading(false);
        if (teamsInfo.memberAdditionError) {
          setMemberAdditionError(teamsInfo.memberAdditionError);
          showSnackbar(
            teamsInfo.memberAdditionError +
              ' Please try adding the member to the teams later!',
            'error'
          );
        } else showSnackbar('Successfully Created Teams!', 'success');
        setTeamsCreated(true);
        setFormData((prevTeamsDetails) => ({
          ...prevTeamsDetails,
          teamsDetails: {
            ...prevTeamsDetails.teamsDetails,
            channelName: teamsInfo.channelName,
            teamsId: teamsInfo.teamsId,
            channelId: teamsInfo.channelId,
            notificationLink: teamsInfo.notificationLink,
          },
        }));
      })
      .catch(() => {
        setLoading(false);
        showSnackbar(
          "Unfortunately, Teams Couldn't be created. Please try again!",
          'error'
        );
      });
  };

  const checkIfFormIncomplete = () => {
    const isAnyFieldEmpty =
      isEmpty(formData.teamsDetails.teamsName.trim()) ||
      isEmpty(formData.teamsDetails.channelName.trim());
    return isAnyFieldEmpty;
  };

  useEffect(() => {
    setCreateTeamsButtonEnabled(checkIfFormIncomplete());
  }, [formData]);
  return formData.teamsDetails.teamsId ? (
    <TeamsSettingsFragment
      locationId={locationId}
      userName={userName}
      formData={formData}
      projectType={projectType}
      memberAdditionError={memberAdditionError}
    />
  ) : (
    <div className="teams-input-form">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            id="teamsName"
            label="Teams Name"
            value={formData.teamsDetails.teamsName || ''}
            name="teamsName"
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            id="channelName"
            label="Channel Name"
            value={formData.teamsDetails.channelName || ''}
            name="channelName"
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="teamsDescription"
            label="Teams Description"
            multiline
            rows={4}
            value={formData.teamsDetails.teamsDescription || ''}
            name="teamsDescription"
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="channelDescription"
            label="Channel Description"
            multiline
            rows={4}
            value={formData.teamsDetails.channelDescription || ''}
            name="channelDescription"
            onChange={handleFormChange}
          />
        </Grid>
      </Grid>
      <Button
        className="step-button"
        variant="contained"
        onClick={createNewTeams}
        disabled={isLoading || isCreateTeamsButtonEnabled}
      >
        {isLoading ? <CircularProgress size={'20px'} /> : 'Create Teams'}
      </Button>
    </div>
  );
};
