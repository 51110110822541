import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import styled from 'styled-components';
import './Sidebar.scss';
import MenuIcon from '@mui/icons-material/Menu';
import { MenuOpen } from '@mui/icons-material';
import CibusCellLogo from '../../assets/images/cibuscell.png';

const SidebarMenu = styled.div<{ close: boolean }>`
  width: 250px;
  height: 100vh;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: ${({ close }) => (close ? '0' : '-100%')};
`;

const ImageWrapper = styled.div`
padding-left: 30px;
padding-bottom: 5px;
}
`;
interface SidebarComponentProps {
  close: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  setClose: Function;
}

const Sidebar: FunctionComponent<SidebarComponentProps> = (props) => {
  const { close, setClose } = props;
  const showSidebar = () => {
    setClose((prev: boolean) => !prev);
  };
  return (
    <div className="sidebar">
      <div className="sidebar-inner-container">
        <div>
          <ImageWrapper>
            <img src="./favicon.ico" alt="" height={30} />
          </ImageWrapper>
          <Link className="sidebar-menu-icon-open" to="#" onClick={showSidebar}>
            <MenuIcon />
          </Link>
        </div>

        <div className="sidebar-closed-container">
          {SidebarData.map((item, index) => {
            return (
              <div key={index} className="sidebar-close-menu-container">
                <Link to={item.path} className="sidebar-close-menu-link">
                  {item.icon}
                </Link>
              </div>
            );
          })}
        </div>
      </div>

      <SidebarMenu close={!close}>
        <Link className="sidebar-menu-icon-close" to="#" onClick={showSidebar}>
          <ImageWrapper>
            <img src={CibusCellLogo} alt="" height={30} />
          </ImageWrapper>
          <MenuOpen />
        </Link>

        {SidebarData.map((item, index) => {
          return (
            <li className="sidebar-menu-items" key={index}>
              <Link className="sidebar-menu-items-links" to={item.path}>
                {item.icon}
                <span style={{ marginLeft: '16px' }}>{item.title}</span>
              </Link>
            </li>
          );
        })}
      </SidebarMenu>
    </div>
  );
};
export default Sidebar;
