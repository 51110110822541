import HttpClient from '../../services/httpClient/httpClient';
import { TeamsDetails, TeamsFormData } from 'interfaces/teams.interface';

class TeamsService {
  private azureAdScope = 'ChatMessage.Send';

  private azureAdAdminScope = 'b12c3523-6472-444b-a90b-eca1bc65df11/default';

  private azureAdScopeForClaims =
    'Tasks.ReadWrite  TeamMember.ReadWrite.All ChatMessage.Send';

  private httpClient;

  private httpClientClaims;

  private httpClientAdmin;

  constructor() {
    this.httpClient = new HttpClient(this.azureAdScope).client;
    this.httpClientClaims = new HttpClient(this.azureAdScopeForClaims).client;
    this.httpClientAdmin = new HttpClient(this.azureAdAdminScope).client;
  }

  async sendNotificationInTeams(api: any, message: string) {
    const result = await this.httpClient.post(api, {
      body: {
        content: message,
      },
    });
    return result;
  }

  async createNewTeams(details: TeamsFormData): Promise<TeamsDetails> {
    const result = await this.httpClientAdmin.post(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/project/teams`,
      details
    );
    return result.data;
  }

  async createNewClaimsInTeams(api: string, message: any) {
    const result = await this.httpClientClaims.post(api, message);
    return result;
  }

  async getDetails(api: string) {
    const result = await this.httpClientClaims.get(api);
    return result;
  }
}
const STeamsService = new TeamsService();
export default STeamsService;
