import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { matches } from 'lodash';

import SLocationService from '../../services/location/location.service';
import AddressAutocomplete from '../../components/AddressAutocomplete/AddressAutocomplete';
import { locationInputValidator } from '../../utils/location';
import { Address } from 'interfaces/location.interface';
import { LocationData, LocationDataError } from 'types/location';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';
import { OGEDigitizationLevel } from 'constants/common';

const initialLocationDetails = {
  name: '',
  address: '',
  cOGEDigitizationLevel: OGEDigitizationLevel.CLow,
};

export default function AddLocationDialog(props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  setRefresh: (value: boolean) => void;
  projectId: string;
  displayLocation: () => void;
}) {
  const { open, setOpen, projectId, displayLocation } = props;

  const [loading, setLoading] = useState(false);

  const [locationDetails, setLocationDetails] = useState<LocationData>(
    initialLocationDetails
  );
  const [locationDetailsError, setLocationDetailsError] =
    useState<LocationDataError>();
  const { showSnackbar } = useSnackbar();

  const handleClose = () => {
    setLocationDetails(initialLocationDetails);
    setLocationDetailsError(undefined);
    setOpen(false);
  };

  const onFieldsChange = async (value: string | Address, key: string) => {
    if (key === 'address' && typeof value !== 'string') {
      setLocationDetails({
        ...locationDetails,
        address: value.freeformAddress,
        lat: value.lat,
        lng: value.lng,
      });
    } else {
      setLocationDetails({
        ...locationDetails,
        [key]: value,
      });
    }
    setLocationDetailsError({
      ...locationDetailsError,
      [key]: '',
    });
  };

  const handleAddNewLocation = async () => {
    setLoading(true);
    if (locationDetails) {
      const errors = locationInputValidator(locationDetails);
      if (matches(errors)({})) {
        const data = {
          ...locationDetails,
          projectId: projectId,
        };
        await SLocationService.addNewLocation(data)
          .then(() => {
            showSnackbar('Location Added SuccessFully!', 'success');
            setLoading(false);
          })
          .catch(() => {
            showSnackbar('Add Location Failed!', 'error');
            setLoading(false);
          });
        handleClose();
        displayLocation();
      } else {
        setLocationDetailsError((prev) => {
          return { ...prev, ...errors };
        });
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>Add New Location </DialogTitle>
        <DialogContent>
          <FormControl required sx={{ minWidth: 360 }}>
            <TextField
              fullWidth
              autoFocus
              margin="dense"
              id="locationName"
              label="Location Name"
              type="text"
              variant="standard"
              name="location-name"
              required
              value={locationDetails?.name}
              onChange={(e) => {
                onFieldsChange(e.target.value, 'name');
              }}
              helperText={locationDetailsError?.name}
              error={Boolean(locationDetailsError?.name)}
            />
            <AddressAutocomplete
              defaultValue={
                locationDetails?.address ? locationDetails?.address : ''
              }
              isRequired={true}
              helperText={locationDetailsError?.address}
              handleFormChange={(e) => {
                onFieldsChange(e, 'address');
              }}
            ></AddressAutocomplete>
            <FormControl required sx={{ mb: 5, mt: 5, minWidth: 250 }}>
              <InputLabel id="OGEDigitization-label">
                OGEDigitization
              </InputLabel>
              <Select
                labelId="OGEDigitization-label"
                id="OGEDigitization-select"
                size="medium"
                sx={{ fontFamily: 'Inria Sans' }}
                label="OGEDigitization"
                value={
                  locationDetails?.cOGEDigitizationLevel
                    ? locationDetails?.cOGEDigitizationLevel
                    : ''
                }
                onChange={(event) => {
                  onFieldsChange(event.target.value, 'cOGEDigitizationLevel');
                }}
              >
                {Object.values(OGEDigitizationLevel)?.map((element) => {
                  return (
                    <MenuItem key={element} value={element}>
                      {element}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleAddNewLocation}
            disabled={loading}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
