import HttpClient from '../httpClient/httpClient';
import { encryptMessage } from 'utils/EncryptUtil';
import { ILocationDetails } from 'types/location';

class LocationService {
  private azureAdScope = 'b12c3523-6472-444b-a90b-eca1bc65df11/default';

  private httpClient;

  constructor() {
    this.httpClient = new HttpClient(this.azureAdScope).client;
  }

  async findLocationNames(projectId: string): Promise<ILocationDetails[]> {
    const result = await this.httpClient.get(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/location/project/${projectId}/names`
    );
    return result.data;
  }

  async findAllLocationNames() {
    const result = await this.httpClient.get(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/location/all`
    );
    return result;
  }

  async deleteLocation(locationId: string) {
    return this.httpClient
      .delete(
        `${process.env.REACT_APP_CIBUSCELL_API_URL}/location/${locationId}`
      )
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async addNewLocation(data: any) {
    try {
      const newLocation = await this.httpClient.post(
        `${process.env.REACT_APP_CIBUSCELL_API_URL}/location`,
        data
      );
      return newLocation;
    } catch (error) {
      console.log('add Location failed', error);
    }
  }

  async autoComplete(searchText: string, signal: AbortSignal): Promise<any> {
    let query = searchText + '/' + new Date().getTime();
    query = encryptMessage(query);
    //changed to non auth api to use in partner-join page
    return this.httpClient.get(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/geo?query=` +
        encodeURIComponent(query),
      { signal }
    );
  }

  async getLocationDetails(locationId: string) {
    const result = await this.httpClient.get(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/location/${locationId}`
    );
    return result.data;
  }

  async updateLocationDetails(locationId: string, locationData: any) {
    try {
      const response = await this.httpClient.patch(
        `${process.env.REACT_APP_CIBUSCELL_API_URL}/location/${locationId}/update-as-admin`,
        locationData
      );
      return response;
    } catch (error) {
      console.log('LocationDetails update failed', error);
      throw error;
    }
  }

  async uploadImage(file: string | Blob, locationId: string) {
    const fd = new FormData();
    fd.append('file', file);
    const path = await this.httpClient.post(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/location/${locationId}/image`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return path.data.projectImage;
  }
}

const SLocationService = new LocationService();
export default SLocationService;
