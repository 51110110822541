import styled from 'styled-components';

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SaveButton = styled.div`
  margin-top: 15px;
`;

const DisplayLabel = styled.p`
  color: red;
`;

const TableWrapper = styled.p`
  height: 400;
  width: '50%';
  margin-top: 20px;
`;

export { MenuContainer, SaveButton, DisplayLabel, TableWrapper };
