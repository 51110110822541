import { graphConfig } from 'config/authConfig';
import HttpClient from '../httpClient/httpClient';
import { IUserDetails } from 'types/users';

class UserService {
  private user: any = undefined;

  private azureAdScope = 'User.Read';

  private httpClient;

  private azureAdScopeForUsers = 'b12c3523-6472-444b-a90b-eca1bc65df11/default';

  private httpClientForUSers;

  constructor() {
    this.httpClient = new HttpClient(this.azureAdScope).client;
    this.httpClientForUSers = new HttpClient(this.azureAdScopeForUsers).client;
  }

  public getUserFromApi() {
    return this.httpClient.get(graphConfig.graphMeEndpoint + '/v1.0/me');
  }

  getUser(): Promise<object> {
    return new Promise((resolve) => {
      if (this.user) {
        resolve(this.user);
      } else {
        this.getUserFromApi().then(({ data }) => {
          this.user = data;
          resolve(data);
        });
      }
    });
  }

  async getAllUserNames() {
    return this.httpClientForUSers
      .get(`${process.env.REACT_APP_CIBUSCELL_API_URL}/user-details/all/names`)
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async uploadAvatar(file: Blob, userId: string) {
    const fd = new FormData();
    fd.append('file', file);
    const path = await this.httpClientForUSers.post(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/user-details/${userId}/avatar`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return path.data.avatar;
  }

  async getUserByUserId(userId: string) {
    return this.httpClientForUSers
      .get(`${process.env.REACT_APP_CIBUSCELL_API_URL}/user-details/${userId}`)
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async getUserDetails(): Promise<IUserDetails> {
    const result = await this.httpClientForUSers.get(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/user-details`
    );
    return result.data;
  }

  async getUsersByProjectId(projectId: string): Promise<IUserDetails[]> {
    const result = await this.httpClientForUSers.get(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/user-details/project/${projectId}`
    );
    return result.data;
  }

  async updateUserDetails(userData: Partial<IUserDetails>, userId: string) {
    const response = await this.httpClientForUSers.patch(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/user-details/${userId}`,
      userData
    );
    return response;
  }

  async addUserDetails(userData: Partial<IUserDetails>) {
    const response = await this.httpClientForUSers.post(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/user-details`,
      userData
    );
    return response;
  }

  async deleteUser(userId: string) {
    return this.httpClientForUSers
      .delete(
        `${process.env.REACT_APP_CIBUSCELL_API_URL}/user-details/${userId}`
      )
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async getAADUsers(searchText: string, signal: AbortSignal) {
    return this.httpClientForUSers.get(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/user-details/users/all?search=${searchText}`,
      { signal }
    );
  }

  async getUserByAadId(aadObjectId: string) {
    const response = await this.httpClientForUSers.get(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/user-details/aad-id/${aadObjectId}`
    );
    return response;
  }

  async nullLastChosenLocation(userId: string) {
    const response = await this.httpClientForUSers.patch(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/user-details/${userId}/null-last-chosen-location`
    );
    return response;
  }

  async checkUserExists(email: string) {
    const response = await this.httpClientForUSers.get(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/user-details/${email}/exists`
    );
    return response;
  }
}

const SUserService = new UserService();
export default SUserService;
