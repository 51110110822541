import HttpClient from '../httpClient/httpClient';

class DriverFunctionService {
  private azureAdScope = 'b12c3523-6472-444b-a90b-eca1bc65df11/default';

  private httpClient;

  constructor() {
    this.httpClient = new HttpClient(this.azureAdScope).client;
  }

  async getDriverFunctions(locationId: number) {
    const result = await this.httpClient.get(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/driver-function/location/${locationId}`
    );
    return result;
  }

  async createDriverFunction(driverFunctionDetails: any) {
    const result = await this.httpClient.post(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/driver-function`,
      driverFunctionDetails
    );
    return result;
  }

  async updateDriverFunction(locationId: number, driverFunctionDetails: any) {
    const result = await this.httpClient.patch(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/driver-function/${locationId}`,
      driverFunctionDetails
    );
    return result;
  }

  async deleteDriverFunction(idList: number[]) {
    const result = await this.httpClient.delete(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/driver-function`,
      {
        data: idList,
      }
    );
    return result;
  }
}

const SDriverFunctionService = new DriverFunctionService();
export default SDriverFunctionService;
