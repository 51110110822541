import { IReportDetails } from 'types/reports';

export const reportInputValidator = (reportDetails: IReportDetails) => {
  let reportError = {};
  if (!reportDetails.reportId.length) {
    reportError = { ...reportError, reportId: 'ReportId is mandatory!' };
  }
  if (!reportDetails.workspaceId.length) {
    reportError = {
      ...reportError,
      workspaceId: 'WorkspaceId is mandatory!',
    };
  }
  if (!reportDetails.datasetId.length) {
    reportError = { ...reportError, datasetId: 'DatasetId is mandatory!' };
  }
  return reportError;
};
