export const msalConfig = {
  auth: {
    clientId: '54393b7a-0f2f-48c8-b275-9c23ec258ae3',
    authority:
      'https://login.microsoftonline.com/0aa9d4d1-555d-4c5b-9589-7a3d72362c30', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['b12c3523-6472-444b-a90b-eca1bc65df11/default'],
};
export const tenantId = '0aa9d4d1-555d-4c5b-9589-7a3d72362c30';

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/',
};
