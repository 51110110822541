import HttpClient from '../../services/httpClient/httpClient';
import { commonConstants } from 'constants/common';

class GridDataService {
  private httpClient;

  constructor() {
    this.httpClient = new HttpClient(commonConstants.AZURE_AD_SCOPE).client;
  }

  async addGridDataRowsNewLocation(locationId: string) {
    try {
      const response = await this.httpClient.post(
        `${process.env.REACT_APP_CIBUSCELL_API_URL}/update-plan/location/${locationId}`,
        locationId
      );

      return response;
    } catch (error) {
      console.log('griddata rows load for new location failed', error);
      throw error;
    }
  }

  async deleteGridDataRows(locationId: string) {
    try {
      const response = await this.httpClient.delete(
        `${process.env.REACT_APP_CIBUSCELL_API_URL}/update-plan/location/${locationId}`
      );
      return response;
    } catch (error) {
      console.log('griddata rows deletion failed', error);
      throw error;
    }
  }
}

const SGridDataService = new GridDataService();
export default SGridDataService;
