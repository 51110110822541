export enum PowerBIReportTypes {
  OVERVIEW = 'OVERVIEW',
  OPTIMIZE = 'OPTIMIZE',
  SIMULATE = 'SIMULATE',
  CUSTOM = 'CUSTOM',
  CAPEXOPEX = 'CAPEXOPEX',
}

export enum ReportStatus {
  ACTIVE = 'ACTIVE',
  HIDE = 'HIDE',
}

export interface IReportDetails {
  powerBIReportTypes: PowerBIReportTypes;
  reportStatus: ReportStatus;
  reportId: string;
  datasetId: string;
  workspaceId: string;
  reportName: string;
  roleName: string;
}
