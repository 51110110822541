import { LocationData } from 'types/location';

export const locationInputValidator = (locationDetails: LocationData) => {
  let error = {};
  if (locationDetails.name?.trim() === '' || locationDetails.name === null) {
    error = {
      ...error,
      name: 'Name cannot be empty!',
    };
  }
  if (
    locationDetails.address?.trim() === '' ||
    locationDetails.address === null
  ) {
    error = {
      ...error,
      address: 'Address cannot be empty!',
    };
  }
  return error;
};

export interface ILocationTypeMap {
  label: string;
  value: number;
}
