import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import SProjectService from '../../services/project/project.service';
import {
  alpha,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Snackbar,
  Alert,
  Paper,
  Toolbar,
  Checkbox,
  IconButton,
  Tooltip,
  TextField,
  OutlinedInput,
  InputAdornment,
  InputLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import { visuallyHidden } from '@mui/utils';
import {
  Order,
  EnhancedTableProps,
  ProjectData,
  EnhancedTableToolbarProps,
} from 'types/project';
import { projectHeadCells } from 'constants/projectTable';
import { stableSort, getComparator } from '../../utils/tableComponents';
import DeleteProjectStepperDialog from './Delete-Project-Stepper-Dialog';
import { toString } from 'lodash';
import { FaEdit } from 'react-icons/fa';
import { IProjectDetails } from 'interfaces/project.interface';
import { CloseOutlined } from '@mui/icons-material';
import './Project.scss';

const headCells = projectHeadCells;

// TABLE HEAD

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof ProjectData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all projects',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        <TableCell>
          <TableCell padding="checkbox">Actions</TableCell>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

// TABLE TOOLBAR

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme: {
            palette: {
              primary: { main: string };
              action: { activatedOpacity: number };
            };
          }) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Project List
        </Typography>
      )}
    </Toolbar>
  );
};

export default function ProjectList(props: {
  refresh: boolean;
  setRefresh: (value: boolean) => void;
}) {
  const { refresh, setRefresh } = props;
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ProjectData>('name');
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState<ProjectData[]>([]);
  const [projectsList, setProjectsList] = useState<ProjectData[]>([]);
  const [selectedRow, setSelectedRow] = useState<any[]>([]);
  const [openDeleteProjectDialog, setOpenDeleteProjectDialog] = useState(false);
  const [editProject, setEditProject] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState<number>();
  const [newProjectDetails, setNewProjectDetails] = useState<
    Partial<IProjectDetails> | undefined
  >();
  const [updateSnackbarOpen, setUpdateSnackbarOpen] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState<boolean>();
  const [searchQuery, setSearchQuery] = useState('');
  const [projectLogo, setProjectLogo] = useState<Blob>();
  const [selectedProjectLogo, setSelectedProjectLogo] = useState('');
  const projectLogoInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (refresh) {
      SProjectService.getAllProjects()
        .then((response) => {
          setProjectsList(response);
          setSelected([]);
          setOrder('asc');
          setOrderBy('name');
        })
        .catch((error) => {
          console.error(error);
        });
      setRefresh(false);
    }
  }, [refresh]);

  const handleUpdateAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setUpdateSnackbarOpen(false);
  };

  const currentProjectDetails = async (projectId: number) => {
    setSelectedProjectId(projectId);
    const currentProjectData = await SProjectService.getProjectDetails(
      projectId.toString()
    );
    setNewProjectDetails(currentProjectData);
  };

  const handleCloseEdit = () => {
    setProjectLogo(undefined);
    setEditProject(false);
  };

  const handleUpdateProject = async () => {
    try {
      const data = newProjectDetails;

      if (selectedProjectId) {
        await SProjectService.updateProjectDetails(
          data,
          selectedProjectId.toString()
        );
        if (projectLogo)
          await SProjectService.uploadLogo(
            projectLogo,
            selectedProjectId.toString()
          );
      }
      setRefresh(true);
      setProjectLogo(undefined);
      setEditProject(false);
      setUpdateSuccess(true);
      setUpdateSnackbarOpen(true);
    } catch (error) {
      setProjectLogo(undefined);
      setEditProject(false);
      setUpdateSuccess(false);
      setUpdateSnackbarOpen(true);
      console.log('Project details update failed', error);
    }
  };

  const handleprojectLogoChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    const file = event.target.files[0];
    setProjectLogo(file);
  };

  const handleProjectLogoClick = () => {
    projectLogoInput?.current?.click();
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ProjectData
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => toString(n.id));
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<HTMLTableRowElement>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const deleteProject = (row: any) => {
    setSelectedRow(row);
    setOpenDeleteProjectDialog(true);
  };

  const handleSearchQueryChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const filteredRows = projectsList.filter((project) => {
      return project?.name?.toLowerCase().includes(searchQuery?.toLowerCase());
    });
    setRows(filteredRows);
  }, [searchQuery, projectsList]);

  const isSelected = (id: number) => selected.indexOf(toString(id)) !== -1;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    // TABLE COMBINED

    <Box sx={{ width: '100%' }}>
      <DeleteProjectStepperDialog
        open={openDeleteProjectDialog}
        setOpen={setOpenDeleteProjectDialog}
        setRefresh={setRefresh}
        projectDetails={selectedRow}
      ></DeleteProjectStepperDialog>
      <FormControl
        sx={{
          marginTop: '10px',
          marginBottom: '10px',
          marginX: '400px',
          width: '300px',
        }}
      >
        <InputLabel htmlFor="outlined-adornment-search">
          Search Projects
        </InputLabel>
        <OutlinedInput
          margin="dense"
          label="Search Projects"
          type="text"
          id="outlined-adornment-search"
          value={searchQuery}
          onChange={handleSearchQueryChange}
          endAdornment={
            searchQuery.length ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setSearchQuery('');
                  }}
                  edge="end"
                >
                  <CloseOutlined />
                </IconButton>
              </InputAdornment>
            ) : (
              <></>
            )
          }
        />
      </FormControl>
      {rows && rows.length > 0 ? (
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event: MouseEvent<HTMLTableRowElement>) =>
                          handleClick(event, toString(row.id))
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.id}
                        </TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.description}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">{row.address}</TableCell>
                        <TableCell>
                          <Tooltip title="Delete">
                            <IconButton onClick={() => deleteProject(row)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => {
                                setEditProject(true);
                                setSelectedProjectLogo(row?.logo);
                                currentProjectDetails(row.id);
                              }}
                            >
                              <FaEdit color="blue" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : projectsList?.length ? (
        <h1
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '210px',
          }}
        >
          No projects for {searchQuery}
        </h1>
      ) : null}
      <Dialog open={editProject} onClose={handleCloseEdit}>
        <DialogTitle>{'Edit Project Data'}</DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '10px !important',
          }}
        >
          <FormControl
            sx={{ minWidth: 360 }}
            style={{ marginBottom: '1.3rem' }}
          >
            <TextField
              size="medium"
              type="string"
              label="Project Name"
              required
              value={newProjectDetails?.name || ''}
              onChange={(e) =>
                setNewProjectDetails({
                  ...newProjectDetails,
                  name: e.target.value,
                })
              }
              inputProps={{
                maxLength: 200,
              }}
            />

            <div>
              <p>Project Logo</p>
              {!projectLogo && !selectedProjectLogo && (
                <div className="edit-project-logo-wrap">
                  <ImageIcon
                    className="new-project-image-icon"
                    onClick={handleProjectLogoClick}
                  />
                </div>
              )}
              {(projectLogo || selectedProjectLogo) && (
                <div className="edit-project-logo-wrap">
                  <img
                    className="new-project-image"
                    src={
                      projectLogo
                        ? URL.createObjectURL(projectLogo)
                        : selectedProjectLogo
                    }
                    onClick={handleProjectLogoClick}
                  />
                </div>
              )}
            </div>

            <input
              type="file"
              ref={projectLogoInput}
              onChange={handleprojectLogoChange}
              style={{ display: 'none' }}
              accept="image/png, image/jpeg"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <div className="button-wrapper">
            <Button onClick={handleCloseEdit}>Cancel</Button>
            <Button onClick={handleUpdateProject} variant="contained">
              {'Update Project'}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={updateSnackbarOpen}
        autoHideDuration={4000}
        onClose={handleUpdateAlertClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <Alert
          onClose={handleUpdateAlertClose}
          severity={updateSuccess ? 'success' : 'error'}
        >
          {updateSuccess
            ? 'Project Data updated successfully'
            : 'Project update failed'}
        </Alert>
      </Snackbar>
    </Box>
  );
}
