import { ProjectDetails } from 'types/project';
import HttpClient from '../httpClient/httpClient';
import { INewUserDetails } from 'pages/Users/components/New-User-Dialog';
import { AxiosRequestConfig } from 'axios';

class ProjectService {
  private azureAdScope = 'b12c3523-6472-444b-a90b-eca1bc65df11/default';

  private httpClient;

  constructor() {
    this.httpClient = new HttpClient(this.azureAdScope).client;
  }

  async createNewProject(newProjectDetails: ProjectDetails) {
    return this.httpClient
      .post(
        `${process.env.REACT_APP_CIBUSCELL_API_URL}/project-onboarding`,
        newProjectDetails
      )
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async uploadLogo(file: string | Blob, projectId: string) {
    const fd = new FormData();
    fd.append('file', file);
    const path = await this.httpClient.post(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/project/${projectId}/logo`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return path.data.logo;
  }

  async findNames() {
    return this.httpClient
      .get(`${process.env.REACT_APP_CIBUSCELL_API_URL}/project/all/names`)
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async getAllProjects() {
    const queryParams: Record<string, string | undefined> = {};

    const config: AxiosRequestConfig = {
      params: queryParams,
    };

    return this.httpClient
      .get(
        `${process.env.REACT_APP_CIBUSCELL_API_URL}/project/all/details`,
        config
      )
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async getMenuConfiguration(projectId: string) {
    return this.httpClient
      .get(
        `${process.env.REACT_APP_CIBUSCELL_API_URL}/project/${projectId}/menu-configuration`
      )
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async updateProjectDetails(projectData: any, projectId: string) {
    const response = await this.httpClient.patch(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/project/${projectId}/update-as-admin`,
      projectData
    );
    return response;
  }

  async getProjectDetails(projectId: string) {
    return this.httpClient
      .get(
        `${process.env.REACT_APP_CIBUSCELL_API_URL}/project/${projectId}/get-as-admin`
      )
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async deleteProject(projectId: string) {
    return this.httpClient
      .delete(
        `${process.env.REACT_APP_CIBUSCELL_API_URL}/project/${projectId}/delete-as-admin`
      )
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async addNewUserToProject(projectId: string, user: INewUserDetails) {
    const result = await this.httpClient.post(
      `${process.env.REACT_APP_CIBUSCELL_API_URL}/project/${projectId}/user`,
      user
    );
    return result;
  }
}
const SProjectService = new ProjectService();
export default SProjectService;
